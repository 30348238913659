<template>
	<div class="sort-panel">
		<button
			v-for="item in list"
			:key="item.key"
			class="sort-panel__btn"
			@click="toggle(item.key)"
			:class="{ active: currentValue === item.key }"
		>
			{{ $t(item.title) }}
			<or-icon
				class="sort-panel__icon"
				:class="{ active: isActiveDirection(item.key, 'asc') }"
				type="pointer-arrow"
			/>
			<or-icon
				class="sort-panel__icon"
				:class="{ active: isActiveDirection(item.key, 'desc') }"
				type="pointer-arrow"
			/>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		updateWithoutUrl: {
			// Если true, то тогда сортировка не передается в url, т. е. она не глобальная(нужно для модалки)
			type: Boolean,
			default: false
		},
    list: {
      type: Array,
      default: () => [
        { key: 'price', title: 'general.price' },
        { key: 'created_at', title: 'general.date' }
      ]
    }
	},
	data() {
		return {
			currentValue: this.$route.query.order_by || 'created_at',
			direction: false // true - asc/false - desc
		}
	},
	computed: {
		config() {
			return {
				DATE: 'created_at',
				PRICE: 'price'
			}
		},
		configDirection() {
			return {
				1: 'asc',
				0: 'desc'
			}
		},
		// list() {
		// 	return [
		// 		{ key: this.config.PRICE, title: 'general.price' },
		// 		{ key: this.config.DATE, title: 'general.date' }
		// 	]
		// }
	},
	methods: {
		isActiveDirection(field, direction) {
			return this.currentValue === field
				? this.configDirection[Number(this.direction)] === direction
				: false
		},
		async toggle(field) {
			// Если меняем поле сортировки, то обнуляем направление
			if (field !== this.currentValue) {
				this.direction = false
			} else {
				this.direction = !this.direction
			}

			this.currentValue = field

			if (this.updateWithoutUrl) {
				this.$emit('updateByComponent', {
					order_by: field,
					direction: this.direction ? 'asc' : 'desc'
				})
			} else {
				await this.$router.push({
					query: {
						...this.$route.query,
						order_by: field,
						direction: this.direction ? 'asc' : 'desc'
					},
					params: { lang: this.$store.state.uiLanguage }
				})

				this.$emit('update')
			}
		}
	}
}
</script>

<style lang="scss">
.sort-panel {
	&__btn {
		padding: 0;
		margin-right: 24px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #808080;
		background-color: transparent;
		border: 0;
		cursor: pointer;

		&:last-child {
			margin-right: 8px;
		}
	}

	&__icon {
		position: relative;

		&:first-child {
			margin-left: 5px;
		}

		&:last-child::before {
			position: absolute;
			top: 0;
			transform: rotate(180deg);
		}

		&.active {
			color: #c99d56;
		}
	}
}
</style>
