<template>
	<div class="search-exeptions">
		<div class="search-exeptions__header">
			<h3 class="search-exeptions__title">{{ $t('general.exeptions') }}</h3>
			<span v-if="count" class="search-exeptions__count">{{ count }}</span>
			<button
				type="button"
				@click="$store.commit('hideModal')"
				class="search-exeptions__close"
			>
				<or-icon type="close" />
			</button>
		</div>

		<div class="search-exeptions__toggle-block exeptions-toggle">
			<ul class="exeptions-toggle__list">
				<li v-for="item in toggleList" :key="item.value">
					<label
						class="exeptions-toggle__label"
						:class="{ active: type === item.value }"
					>
						{{ $t(item.title) }}
						<input type="radio" hidden v-model="type" :value="item.value" />
					</label>
				</li>
			</ul>
		</div>

		<component
			:is="config[type]?.component"
			:type="config[type]?.type"
			:key="config[type]?.type"
		/>

		<template v-if="isShowAddExeptionBlock">
			<Transition name="exeptions">
				<addExeption
					v-if="isShowAddExeptions"
					:type="config[type]?.type"
					class="search-exeptions__add-exept"
				/>
			</Transition>

			<or-button
				kind="solid-border"
				@click="isShowAddExeptions = !isShowAddExeptions"
				class="search-exeptions__toggle-add-exeptions"
			>
				{{ isShowAddExeptions ? $t('general.hide') : $t('general.add') }}
				{{ $t('general.exeptions') }}
			</or-button>
		</template>
	</div>
</template>

<script>
import addExeption from './components/addExeption.vue'
import estateList from './components/estateList.vue'
import commonList from './components/commonList.vue'

export default {
	components: { addExeption, estateList, commonList },
	data() {
		return {
			type: 'projects',
			isShowAddExeptions: false
		}
	},
	computed: {
		config() {
			return {
				projects: {
					component: 'commonList',
					type: 'projects'
				},
				developers: {
					component: 'commonList',
					type: 'developers'
				},
				realEstate: {
					component: 'estateList'
				}
			}
		},
		isShowAddExeptionBlock() {
			return this.type !== 'realEstate'
		},
		toggleList() {
			return [
				{
					title: 'general.projects',
					value: 'projects'
				},
				{
					title: 'general.developers',
					value: 'developers'
				},
				{
					title: 'general.realEstate',
					value: 'realEstate'
				}
			]
		},
		exeptions() {
			return this.$store.state.exeptions
		},
		count() {
			return (
				this.exeptions.estates.length +
				this.exeptions.projects.length +
				this.exeptions.developers.length
			)
		}
	}
}
</script>

<style lang="scss" scoped>
.exeptions-enter-active {
	transition: all 0.3s ease-out;
}

.exeptions-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.exeptions-enter-from,
.exeptions-leave-to {
	transform: translateY(20px);
	opacity: 0;
}
</style>

<style lang="scss">
.search-exeptions {
	position: relative;
	width: auto;
	min-height: 550px;
	padding: 40px 37px;
	padding-bottom: 0;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
	border-radius: 4px;

	@media (max-width: 500px) {
		padding: 30px 20px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 25px;
	}

	&__title {
		margin: 0;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
	}

	&__count {
		margin-right: 6px;
		margin-left: auto;
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
		color: #c99d56;
	}

	&__close {
		width: 24px;
		height: 24px;
		padding: 0;
		background-color: transparent;
		border: 0;
		cursor: pointer;
	}

	.exeptions-toggle {
		margin-bottom: 20px;

		&__list {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
		}

		&__label {
			display: block;
			padding: 10px;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #000000;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			cursor: pointer;
			text-align: center;

			&.active {
				color: #c99d56;
				border-color: #c99d56;
			}
		}
	}

	&__add-exept {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -40px;
			right: 0;

			width: 395px;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	&__toggle-add-exeptions {
		display: block;
		width: 100%;
		margin-bottom: 40px;
	}
}
</style>
