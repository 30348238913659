export const getDateInFormat = (payload, lang) => {
	const date = new Date(payload)
	return `${date.getDate()} ${date
		.toLocaleString(lang, { month: 'long' })
		.toLocaleLowerCase()} ${date.getFullYear()}`
}

export const getQuarterDate = (date) => {
	const dateObject = new Date(date)
	return `Q${Math.floor(
		(dateObject.getMonth() + 3) / 3
	)} ${dateObject.getFullYear()}`
}
