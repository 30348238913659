<template>
	<div class="tray-item" :class="className">
		<div v-if="content.type[0] === 'success'">
			{{ $t('general.success') }}
		</div>
		<div v-else-if="content.type[0] === 'error'">
			{{ $t('general.error') }}
		</div>
		<div v-else>{{ $t('general.message') }}</div>
		<p>{{ content.text }}</p>
	</div>
</template>

<script>
export default {
	name: 'TrayItem',
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			timeout: null
		}
	},
	mounted() {
		this.timeout = setTimeout(
			() => this.$store.commit('removeFromTray', this.content.id),
			4000
		)
	},
	computed: {
		className() {
			return {
				'tray-item--error': this.content.type[0] === 'error',
				'tray-item--success': this.content.type[0] === 'success'
			}
		}
	}
}
</script>

<style lang="scss">
.tray-item {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: space-between;
	padding: 15px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	background: #e6e6e6;
	color: #000000;
	transition: all 0.3s ease;

	div {
		font-weight: 700;
		font-size: 16px;
	}

	p {
		margin: 0;
		font-size: 12px;
		line-height: 16px;
	}

	i {
		font-size: 20px;

		&::before {
			content: '\e911';
		}
	}

	&--error {
		background: #fbdbdb;
		color: #000000;

		i {
			font-size: 18px;

			&::before {
				content: '\e909';
			}
		}
	}

	&--success {
		background: #dbfbe0;
		color: #000000;

		i::before {
			content: '\e907';
		}
	}
}
</style>
