<template>
	<label
		class="main-textarea"
		:class="[attributes.class, { 'error-input': error.status }]"
	>
		<or-icon
			v-if="iconBefore"
			:type="iconBefore"
			class="main-textarea__icon main-textarea__icon--before icn-before"
		/>
		<span
			v-if="prefix"
			:type="prefix"
			class="main-textarea__icon main-textarea__icon--before icn-before"
			v-html="prefix"
		/>
		<textarea
			v-bind="attributes.attrs"
			class="main-textarea__content main-textarea-content"
			:class="{ 'have-after-icon': iconAfter }"
			:value="modelValue"
			@input="updateValue"
		/>
		<span
			v-if="placeholderWrap && !modelValue"
			class="main-textarea__placeholder"
		>
			{{ placeholderWrap }}
		</span>
		<or-icon
			v-if="iconAfter"
			:type="iconAfter"
			class="main-textarea__icon main-textarea__icon--after icn-after"
		/>
		<div v-if="counter" class="main-textarea__counter">
			<span :class="{ 'text-red': modelValue.length > maxLength }">{{
				modelValue.length
			}}</span>
			/
			<span>{{ maxLength }}</span>
		</div>
	</label>
	<transition name="fade" mode="out-in">
		<div v-if="error.status" class="main-input__error">
			{{ error.text }}
		</div>
	</transition>
</template>

<script>
export default {
	name: 'GlobalTextarea',
	props: {
		iconBefore: {
			type: String,
			default: null
		},
		prefix: {
			type: String,
			default: null
		},
		iconAfter: {
			type: String,
			default: null
		},
		modelValue: {
			type: null
		},
		counter: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
			default: 200
		},
		error: {
			type: Object,
			default: () => ({
				status: false,
				text: ''
			})
		},
		placeholderWrap: String
	},
	inheritAttrs: false,
	computed: {
		attributes() {
			const { class: classes, ...rest } = this.$attrs
			return {
				class: classes,
				attrs: { ...rest }
			}
		}
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value)
		}
	}
}
</script>

<style lang="scss">
.main-textarea {
	position: relative;
	display: block;

	&.error-input {
		.main-textarea__content {
			border: 1px solid #db3939;
			background: rgba(219, 57, 57, 0.05);
		}
	}

	&__content {
		width: 100%;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		resize: none;

		@media (max-width: 768px) {
			font-size: 16px;
		}

		&:hover,
		&:focus,
		&:active {
			outline: 0;
			border: 1px solid #c99d56 !important;
		}

		&:read-only {
			cursor: pointer;
		}
		&::placeholder {
			color: #808080;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		margin: auto 0;
		z-index: 5;
		transition: all 0.5s linear;
		pointer-events: none;

		&--before {
			left: 15px;
		}

		&--after {
			right: 20px;
		}
	}
	&__placeholder {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: 40px;
		font-size: 14px;
		max-width: 57%;
		line-height: 1.2;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -moz-box;
		-moz-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-clamp: 2;
		color: #808080;
		pointer-events: none;
	}
	// Сделал отдельный тип инпута. Возможно можно как-то оптимизировать
	&.main-type {
		.icn-before {
			color: #b3b3b3;
		}

		.icn-before ~ .main-textarea__content {
			padding-left: 40px;
		}

		.main-textarea__content {
			padding: 0 15px;
			height: 48px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			transition: all 0.5s linear;

			&.have-after-icon {
				padding-right: 35px;
			}
		}

		&:hover,
		&:focus,
		&:active,
		&.active-textarea {
			.main-textarea {
				box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
				border-color: #c99d56;
				transition: all 0.5s linear;
			}

			.icn-before {
				color: #c99d56;
				transition: all 0.5s linear;
			}
		}

		&.active-textarea {
			.icn-after {
				transition: all 0.5s linear;
				transform: rotate(0);
			}
		}

		&.have-content {
			.icn-before {
				color: #c99d56;
				transition: all 0.5s linear;
			}
		}
	}

	&__counter {
		float: right;
		color: black;

		.text-red {
			color: red;
		}
	}
}
</style>
