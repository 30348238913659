<template>
	<div class="modal">
		<Transition name="slide-fade" mode="out-in">
			<div v-if="currentModal.isShow" class="modal__backfrop">
				<div
					class="modal__wrapper"
					:class="{ 'modal-middle': currentModal.name === 'PlaceCarousel' }"
				>
					<component
						:is="currentModalComponent"
						:content="currentModal.props"
						ref="popup"
						id="modalContent"
            v-click-away="clickAwayHandler"
					/>
				</div>
			</div>
		</Transition>
		<div v-if="currentModal.isShow" class="modal__close" @click="hide"></div>
	</div>
</template>

<script>
import vClickAway from "@/directives/vClickAway";

const modals = {}

require
	.context('./modals', true, /[A-Z]\w+\.(vue)$/)
	.keys()
	.forEach(async (fileName) => {
		const filePaths = fileName.split('/')
		let compPath = ''

		if (filePaths.length > 3) {
			return
		} else if (filePaths.length === 3) {
			compPath = filePaths.filter((itm) => itm !== '.')
			compPath = compPath.join('/')
		} else {
			compPath = filePaths.at(-1)
		}

		const compName = filePaths.pop().replace(/\.\w+$/, '')

		modals[compName] =
			(await import(`@/components/modals/${compPath}`)).default ||
			(await import(`@/components/modals/${compPath}`))
	})

export default {
	name: 'Modal',
	methods: {
		hide() {
      if (this.$store.state.currentModal?.isShow) {
        this.$store.commit('hideModal')
      }
		},
    clickAwayHandler() {
      if (this.$store.state.currentModal?.time && (Date.now() - this.$store.state.currentModal.time) > 100) {
        this.hide()
      }
    },
		onPopstate() {
			this.hide()
		}
		// popupClickAway(event) {
		// 	const popup = document.getElementById('modalContent')
		// 	if (!popup.contains(event.target)) {
		// 		this.hide()
		// 	}
		// }
	},
  directives: {
    'click-away': vClickAway
  },
	computed: {
		currentModal() {
			return this.$store.state.currentModal
		},
		currentModalComponent() {
			return modals[this.currentModal.name]
		}
	},
	mounted() {
		window.addEventListener('popstate', this.onPopstate)
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.$store.state.currentModal?.isShow) this.hide()
    })
	},
	unmounted() {
		window.removeEventListener('popstate', this.onPopstate)
	}
}
</script>

<style lang="scss">
.modal {
	// .owl-carousel {
	// 	.owl-stage {
	// 		display: flex !important;
	// 		justify-content: center !important;
	// 		align-items: center !important;
	// 	}
	// }

	// slide fade
	.slide-fade-enter-active {
		transition: all 0.2s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateX(20px);
		opacity: 0;
	}
}
</style>

<style lang="scss">
.modal {
	&__container {
		height: 100vh;
	}

	&__backfrop {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 999;
		background-color: transparent;
		background-color: rgba(0, 0, 0, 0.7);
	}

	&__wrapper {
		display: flex;
		height: 100vh;
		margin: 10px;
		justify-content: center;
		align-content: center;
		align-items: center;

		&.modal-middle {
			position: fixed;
			//top: 50%;
			//left: 50%;
			//transform: translateX(-50%) translateY(-50%);
			margin: 0px;
			padding: 20px;
			width: 100%;
			height: 100%;
		}
	}
	&__close {
		position: fixed;
		top: 20px;
		right: 35px;
		width: 19px;
		height: 19px;
		z-index: 999;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 5px;
			width: 20px;
			height: 2px;
			background: gray;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}
</style>
