<template>
	<div class="common-modal create-request-modal">
		<h3 class="common-modal__header">
			{{ $t('modal.titleApplication') }}
		</h3>
		<form @submit.prevent="sendRequest" class="common-modal__container">
			<or-input
				:placeholder="$t('general.name')"
				v-model="formData.name"
				required
				icon-before="profile"
				class="main-type common-modal__input"
			/>
			<TelInput required class="common-modal__input" v-model="formData.phone" />
			<or-button
				type="submit"
				:height="48"
				class="w-full"
				aria-label="Button request"
			>
				{{ $t('web.callback_submit') }}
			</or-button>
		</form>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

export default {
	name: 'CreateRequestBuy',
	components: {
		TelInput: defineAsyncComponent(() => import('@/components/TelInput.vue'))
	},
	data() {
		return {
			isFinished: false,
			formData: {
				phone: '',
				name: '',
				type: 'developer'
			}
		}
	},
	methods: {
		async sendRequest() {
			const clone = Object.assign({}, this.formData)
			clone.phone = `${clone.phone.split(' ').join('')}`

			const route = this.$route
			const routeName = route.name
			const slug = route.params.slug

			let type
			switch (routeName) {
				case 'SoloArea':
					type = 'region'
					break
				case 'SoloProject':
					type = 'project'
					break
				case 'SoloAds':
					type = 'announcement'
					break
				case 'SoloDeveloper':
					type = 'developer'
					break
				case 'SoloComplex':
					type = 'complex'
					break
				default:
					break
			}

			clone.type = type
			clone.type_id = slug
			clone.url = document.URL

			createRequest(requestConfigs.POSTSendRequest, {
				jsonPayload: clone
			}).then(() => {
				this.$store.commit('showModal', {
					name: 'SuccessRequestModal'
				})
			})
		}
	}
}
</script>

<style lang="scss">
.common-modal.create-request-modal {
	@media screen and (max-width: 479px) {
		padding: 40px;
	}
}
</style>
