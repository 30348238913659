<template>
	<button :type="type" v-bind="attributes" :class="classes" :style="styles">
		<span class="button__content">
			<slot></slot>
		</span>
	</button>
</template>

<script>
export default {
	props: {
		kind: {
			type: String,
			default: 'main'
		},
		type: {
			type: String,
			default: 'button'
		},
		height: {
			type: Number,
			default: null
		},
		fontSize: {
			type: String,
			default: null
		},
		lineHeight: {
			type: String,
			default: null
		}
	},
	computed: {
		classes() {
			return `button ${this.kind} ${this.$attrs.class || ''}`
		},
		attributes() {
			const atrs = { ...this.$attrs }
			delete atrs.class

			return atrs
		},
		styles() {
			return {
				height: this.height ? this.height + 'px' : undefined,
				fontSize: this.fontSize ? this.fontSize : undefined,
				lineHeight: this.lineHeight ? this.lineHeight : undefined
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/components/button';
</style>
