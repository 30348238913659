<template>
	<div class="delete-agent">
		<p>
			{{ $t('web.delete_agent_text') }}
		</p>
		<div class="delete-agent__btns">
			<or-button @click="submit" :height="48">
				{{ $t('web.yes') }}
			</or-button>
			<or-button @click="$store.commit('hideModal')" :height="48">
				{{ $t('web.no') }}
			</or-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DeleteAgent',
	props: {
		content: {
			type: Object,
			default: () => ({
				callback: () => {}
			})
		}
	},
	methods: {
		submit() {
			this.$store.commit('hideModal')

			this.content.callback()
		}
	}
}
</script>

<style lang="scss">
.delete-agent {
	padding: 60px 80px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
	max-width: 580px;
	width: 100%;
	& > p {
		font-size: 24px;
		font-weight: 700;
		line-height: normal;
		text-align: center;
		margin: 0;
	}
	&__btns {
		display: flex;
		align-items: center;
		gap: 30px;
		width: 100%;
		justify-content: center;
		& .button {
			max-width: 160px;
			width: 100%;
		}
	}
	@media screen and (max-width: 479px) {
		padding: 20px;
		&__btns {
			gap: 20px;
		}
		& > p {
			font-size: 16px;
		}
	}
}
</style>
