export function formatNumber(amount, floor = false) {
	if (!amount || amount < 0) return 0
	let pow = Math.pow(10, 2)
	let number
	if (floor) number = Math.floor((amount / 1) * pow) / pow
	else number = Math.ceil((amount / 1) * pow) / pow
	let val = number.toFixed(2).replace('.00', '').replace('.', ',')
	return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatCash(n) {
	if (n < 1e3) return n
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
	if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
	if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}
