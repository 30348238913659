import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes.js'
import { getBrowserLocale } from '../i18n'
import store from '@/store'

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from) {
		if (to.meta?.scrollTop) {
			return { top: 0 }
		}

		if (from.name !== to.name) {
			return { top: 0 }
		}
	}
})

function calculateInitialLocale() {
	const browserLocale = getBrowserLocale({ countryCodeOnly: true })
	const isBrowserLocaleAccepted = process.env.VUE_APP_ACCEPTED_LANGUAGES?.split(
		','
	).find((lang) => browserLocale === lang)

	return isBrowserLocaleAccepted
		? browserLocale
		: process.env.VUE_APP_I18N_FALLBACK_LOCALE
}

router.beforeEach((to, _, next) => {
	let isBrowserLocaleAccepted = process.env.VUE_APP_ACCEPTED_LANGUAGES?.split(
		','
	).find((lang) => to.params?.lang === lang)
	if (
		store.state.uiLanguage.length &&
		store.state.uiLanguage === to.params?.lang
	) {
		next()
	} else if (
		store.state.uiLanguage.length &&
		store.state.uiLanguage !== to.params?.lang &&
		isBrowserLocaleAccepted
	) {
		store.commit('setUiLanguage', to.params?.lang)
		next()
	} else if (!isBrowserLocaleAccepted || !to.params?.lang) {
		let lang = store.state.uiLanguage
		if (!(lang && lang.length > 0)) {
			lang = calculateInitialLocale()
		}
		store.commit('setUiLanguage', lang)
		let path = to.path
		if (to.params?.lang) {
			path = path.slice(1)
			const idx = path.indexOf('/')
			path = path.slice(idx)
		}
		path = lang + path
		next({
			path: path
		})
	} else {
		if (isBrowserLocaleAccepted && (!store.state.uiLanguage || store.state.uiLanguage.length === 0)) {
			store.commit('setUiLanguage', isBrowserLocaleAccepted)
		}
		next()
	}
})

router.beforeEach((to, _, next) => {
	if (to.meta.brokerAuth) {
		if (store.state.broker.token) {
			next()
		} else {
			next({
				name: 'Login',
				params: { lang: store.state.uiLanguage }
			})
		}
	} else {
		const { token, profile } = store.state.broker
		if (to.meta.showNotAuth && token) {
			// Если мы заполнили профиль, а именно добавили phone у агента или (name && website) у агенства, то профиль считается заполненным и ведем на поиск.
			// В противном случае в профиль
			if (store.getters.isBroker && profile.phone) {
				next({
					name: 'BrokerSearch',
					params: { lang: store.state.uiLanguage }
				})
			} else if (profile.name && profile.website) {
				next({
					name: 'BrokerSearch',
					params: { lang: store.state.uiLanguage }
				})
			} else {
				next({
					name: 'BrokerProfile',
					params: { lang: store.state.uiLanguage }
				})
			}
		} else {
			next()
		}
	}
})

router.beforeEach((to, _, next) => {
	const { token, profile } = store.state.broker
	if (to.meta.brokerAuth && token && !!profile.brokers &&
		(to.name === 'Units' || to.name === 'CreateOffer' || to.name === 'BrokerProject' || to.name === 'BrokerSearch'))
	{
		next({
			name: 'BrokerProfile',
			params: {lang: store.state.uiLanguage}
		})
	} else {
		next()
	}
})

export default router
