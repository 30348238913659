<template>
	<div class="tray">
		<div class="tray__list">
			<transition-group>
				<TrayItem
					v-for="item in $store.state.trayItems"
					:key="item.id"
					:content="item"
				/>
			</transition-group>
		</div>
	</div>
</template>

<script>
import TrayItem from './components/Item'

export default {
	name: 'Tray',
	components: { TrayItem }
}
</script>

<style lang="scss">
.tray {
	position: fixed;
	right: 20px;
	top: 20px;
	max-width: 300px;
	width: 100%;
	z-index: 1000;

	&__list {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
</style>
