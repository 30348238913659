<template>
	<label
		class="main-input"
		:class="[attributes.class, { 'error-input': error.status }]"
	>
		<or-icon
			v-if="iconBefore"
			:type="iconBefore"
			class="main-input__icon main-input__icon--before icn-before"
		/>
		<span
			v-if="prefix"
			:type="prefix"
			class="main-input__icon main-input__icon--before icn-before"
			v-html="prefix"
		/>

		<input
			v-bind="attributes.attrs"
			class="main-input__content main-input-content"
			:class="{ 'have-after-icon': iconAfter, 'text-truncate': truncate }"
			:value="modelValue"
			@input="updateValue"
			@paste.prevent="_pasteHandler"
			:disabled="disabled"
			:type="type"
		/>
		<span v-if="placeholderWrap && !modelValue" class="main-input__placeholder">
			{{ placeholderWrap }}
		</span>
		<or-icon
			v-if="iconAfter"
			:type="iconAfter"
			@click.stop="$emit('clickIconAfter')"
			class="main-input__icon main-input__icon--after icn-after"
		/>
		<div
			v-if="isPasswordType"
			@click="activePassword"
			class="main-input__icon main-input__icon--eye main-input__icon--after"
		>
			<Viewed v-if="!isActivePassword" />
			<NotViewed v-else />
		</div>
	</label>
	<transition name="fade" mode="out-in">
		<div v-if="error.status" class="main-input__error">
			{{ error.text }}
		</div>
	</transition>
</template>

<script>
import Viewed from '@/components/svg/Viewed.vue'
import NotViewed from '@/components/svg/NotViewed.vue'

export default {
	name: 'GlobalInput',
	components: { NotViewed, Viewed },
	props: {
		iconBefore: {
			type: String,
			default: null
		},
		prefix: {
			type: String,
			default: null
		},
		iconAfter: {
			type: String,
			default: null
		},
		modelValue: {
			type: null
		},
		error: {
			type: Object,
			default: () => ({
				status: false,
				text: ''
			})
		},
		pasteHandler: {
			type: Function,
			default: (value, prev) => value
		},
		disabled: {
			type: Boolean,
			default: false
		},
		truncate: Boolean,
		placeholderWrap: String
	},
	inheritAttrs: false,
	data() {
		return {
			isActivePassword: true
		}
	},
	computed: {
		attributes() {
			const { class: classes, ...rest } = this.$attrs
			return {
				class: classes,
				attrs: { ...rest }
			}
		},
		isPasswordType() {
			return this.attributes.attrs.type === 'password'
		},
		type() {
			if (this.isPasswordType) {
				return this.isActivePassword ? 'password' : 'text'
			} else {
				return this.attributes.attrs.type
			}
		}
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value)
		},
		activePassword() {
			this.isActivePassword = !this.isActivePassword
		},
		_pasteHandler(event) {
			this.$emit(
				'update:modelValue',
				this.pasteHandler(
					(event.clipboardData || window.clipboardData).getData('text'),
					this.modelValue
				)
			)
		}
	}
}
</script>

<style lang="scss">
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.main-input {
	position: relative;
	display: block;

	&__content {
		width: 100%;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		@media (max-width: 768px) {
			font-size: 16px;
		}

		&:hover,
		&:focus,
		&:active {
			outline: 0;
			border: 1px solid #c99d56 !important;
		}

		&:read-only {
			cursor: pointer;
		}
		&::placeholder {
			color: #000000;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		margin: auto 0;
		z-index: 5;
		transition: all 0.5s linear;
		cursor: pointer;

		&--before {
			left: 15px;
		}

		&--after {
			right: 20px;
		}
		&--eye {
			z-index: 3;
			cursor: pointer;
			pointer-events: auto;
			background: #ffffff;
			right: 10px;
			width: 30px;
			height: 30px;
			svg {
				width: 22px;
				height: 22px;
				path {
					fill: #808080;
				}
			}
		}
	}

	&__error {
		color: #db3939;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	&__placeholder {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: 40px;
		font-size: 14px;
		max-width: 57%;
		line-height: 1.2;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -moz-box;
		-moz-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-clamp: 2;
		color: #000000;
		pointer-events: none;
	}

	// Сделал отдельный тип инпута. Возможно можно как-то оптимизировать
	&.main-type {
		.icn-before {
			color: #b3b3b3;
		}

		.icn-before ~ .main-input__content {
			padding-left: 40px;
		}
		.main-input__content {
			padding: 0 15px;
			height: 48px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			transition: all 0.5s linear;
			&.have-after-icon {
				padding-right: 35px;
			}
		}
		&:hover,
		&:focus,
		&:active,
		&.active-input {
			.main-input {
				box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
				transition: all 0.5s linear;
				&__content {
					&:disabled {
						border: 1px solid rgba(0, 0, 0, 0.1);
					}
				}
			}

			.icn-before {
				color: #c99d56;
				transition: all 0.5s linear;
			}
		}

		&.active-input {
			.icn-after {
				transition: all 0.5s linear;
				transform: rotate(0);
			}
		}
		&.have-content {
			.main-input__content {
				border: 1px solid #c99d56;
			}
			.icn-before {
				color: #c99d56;
				transition: all 0.5s linear;
			}
		}
	}

	&.error-input {
		.main-input-content {
			border: 1px solid #db3939;
			background: rgba(219, 57, 57, 0.05);
		}
	}
}
</style>
