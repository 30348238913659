<template>
	<div class="company-modal">
		<div class="company-modal__body">
			<company-agent />
			<div class="company-modal__move-to">
				<span></span>
				Move to
				<span></span>
			</div>
			<company-no-slot />
			<company-free-slot v-for="item in 8" :key="item" />
		</div>
<!--		<or-button class="w-180 company-modal__btn"> Button </or-button>-->
	</div>
</template>
<script>
import CompanyAgent from '@/components/modals/CompanyOffice/components/CompanyAgent.vue'
import CompanyFreeSlot from '@/components/modals/CompanyOffice/components/CompanyFreeSlot.vue'
import CompanyNoSlot from '@/components/modals/CompanyOffice/components/CompanyNoSlot.vue'

export default {
	name: 'CompanyModal',
	components: { CompanyNoSlot, CompanyFreeSlot, CompanyAgent }
}
</script>

<style lang="scss">
@import '@/styles/components/modal/company-modal';
</style>
