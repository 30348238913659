import { createSSRApp } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import App from './App.vue'

import router from './router'
import store from './store'

import { i18n } from '@/i18n'
import GlobalComponents from './plugins/global-components'

import 'vue3-carousel/dist/carousel.css'

const app = createSSRApp(App)
	.use(store)
	.use(i18n)
	.use(router)

	.component('or-carousel', Carousel)
	.component('or-carousel-navigation', Navigation)
	.component('or-slide', Slide)

GlobalComponents.register(app)

app.mount('#app')
