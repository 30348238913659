<template>
	<picture
		class="image-component"
		:class="{ 'hidden--img': isLoad }"
		:width="width ?? 'auto'"
		:height="height ?? 'auto'"
	>
		<source
			v-if="srcset?.url"
			type="image/webp"
			:width="width ?? 'auto'"
			:height="height ?? 'auto'"
			:srcset="srcset?.url"
			class="asd"
		/>
		<img
			:loading="loading"
			crossorigin="anonymous"
			v-bind="$attrs"
			:src="imgSrc?.url"
			:width="width ?? 'auto'"
			:height="height ?? 'auto'"
			@load="load"
			:class="{ 'image-component__noPhoto': !this.src && !this.isLoading }"
			:alt="alt"
		/>
	</picture>
	<or-skeleton v-if="!isLoading && isLoad" />
</template>

<!-- Суть логики -->
<!-- Если передаётся ширина, то мы находим ближайшую картинку с большим размером в двух форматах -->
<!-- Если ширина не передается, то выводим самую большую -->

<script>
export default {
	props: {
		src: {
			type: [Object, String],
			default: null
		},
		width: [Number, String],
		height: [Number, String],
		isLogo: [Boolean],
		isLoading: [Boolean],
		alt: [String],
		loading: {
			type: String,
			default: 'lazy'
		}
	},
	data() {
		return {
			isLoad: true
		}
	},
	methods: {
		_getImageData(type) {
			if (!this.src && !this.isLoading) {
				return { url: require(`@/assets/img/svg/logo.svg`) }
			}

			const arrImage = this.src?.files
				?.filter((itm) => itm.extension === type)
				?.map((itm) => ({
					...itm,
					width_height: parseInt(itm.width_height.replace('x', ''))
				}))
				?.sort((a, b) => a.width_height > b.width_height)

			return this.width
				? arrImage?.find((itm) => +itm.width_height > +this.width)
				: arrImage?.reduce((acc, curr) =>
						acc.width_height > curr.width_height ? acc : curr
				  )
		},
		load() {
			this.isLoad = false
		}
	},
	computed: {
		srcset() {
			if (typeof this.src === 'string') {
				return
			}

			return this._getImageData('webp')
		},
		imgSrc() {
			if (typeof this.src === 'string') {
				return { url: this.src }
			}

			return this._getImageData('webp')
		}
	}
}
</script>

<style scoped>
.hidden--img {
	position: absolute;
	height: 0;
	visibility: hidden;
	width: 0 !important;
}
</style>

<style lang="scss" scoped>
.image-component {
	img {
		vertical-align: middle;
	}
	&__noPhoto {
		object-fit: scale-down !important;
	}
	.skeleton-basic {
		width: 100%;
		height: 100%;
	}
}
</style>
