<template>
	<div class="search-modal">
		<header class="search-modal__header">
			<p class="search-modal__result-count">
				{{ meta.total }}
				<span>
					{{ $t('general.results') }}
				</span>
			</p>

			<SortPanel
				@updateByComponent="(evt) => get(false, evt)"
				:update-without-url="true"
			/>
		</header>

		<div class="search-modal__content" @scroll.passive="handleScroll">
			<EstateCard
				v-for="item in list"
				:key="item.id"
				:content="item"
				class="search-modal__item"
			/>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'

import EstateCard from '@/components/cards/Estate'
import SortPanel from '@/components/SortPanel/index.vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({
				list: [],
				meta: {},
				payload: {}
			})
		}
	},
	components: {
		SortPanel,
		EstateCard
	},
	data() {
		return {
			list: [],
			meta: {},
			payload: {},
			loading: false
		}
	},
	beforeMount() {
		this.list = [...this.content.list]
		this.meta = { ...this.content.meta }
		this.payload = { ...this.content.payload }
	},
	methods: {
		handleScroll: debounce(async function (evt) {
			const scrollElement = evt.target

			if (
				scrollElement?.scrollTop + scrollElement?.clientHeight >=
				scrollElement?.scrollHeight - 1
			) {
				if (this.loading) {
					return
				}

				// Проверяем остались ли посты
				if (this.meta.current_page < this.meta.last_page) {
					await this.get(true)
				}
			}
		}, 300),
		async get(more, sortData) {
			this.loading = true

			const payload = {
				...this.payload,
				...sortData,
				per_page: 24,
				page: more ? this.meta?.current_page + 1 : this.meta?.current_page
			}

			return createRequest(requestConfigs.GETSearch, {
				queryPayload: {
					...payload
				}
			})
				.then((result) => {
					if (!more) {
						this.list.length = 0
					}

					this.list.push(...result.response.items)
					this.meta = result.response.meta
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>

<style lang="scss">
.search-modal {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
	border-radius: 4px;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		padding: 0 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	&__result-count {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 45px;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
		color: #000000;

		span {
			flex-basis: 100%;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			color: #808080;
		}
	}

	&__content {
		max-height: 600px;
		min-height: 400px;
		overflow-y: auto;
		padding: 0 20px;
	}

	&__item {
		margin: 0 auto;
		margin-bottom: 40px;
	}
}
</style>
