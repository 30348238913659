<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.59 7.24076C11.4603 9.26947 9.31788 10.5297 6.99992 10.5297C4.68046 10.5297 2.53805 9.26947 1.40832 7.24076C1.32131 7.08323 1.32131 6.9167 1.40832 6.75931C2.53805 4.73063 4.68046 3.47052 6.99992 3.47052C9.31788 3.47052 11.4603 4.73063 12.59 6.75931C12.6786 6.9167 12.6786 7.08323 12.59 7.24076ZM13.3117 6.35709C12.0364 4.06658 9.61795 2.64386 6.99992 2.64386C4.38039 2.64386 1.96192 4.06658 0.68666 6.35709C0.458613 6.76577 0.458613 7.2343 0.68666 7.64268C1.96192 9.93318 4.38039 11.3562 6.99992 11.3562C9.61795 11.3562 12.0364 9.93318 13.3117 7.64268C13.5397 7.23433 13.5397 6.76577 13.3117 6.35709ZM6.99992 8.69574C7.93461 8.69574 8.69526 7.93509 8.69526 6.99997C8.69526 6.06484 7.93461 5.30419 6.99992 5.30419C6.06373 5.30419 5.30308 6.06484 5.30308 6.99997C5.30308 7.93509 6.06375 8.69574 6.99992 8.69574ZM6.99992 4.47753C5.60763 4.47753 4.47642 5.60921 4.47642 6.99999C4.47642 8.39108 5.60766 9.52229 6.99992 9.52229C8.39071 9.52229 9.52192 8.39105 9.52192 6.99999C9.52192 5.60921 8.39071 4.47753 6.99992 4.47753Z"
			fill="#808080"
		/>
	</svg>
</template>
