<template>
	<Transition name="notification">
		<div v-if="isActive" :class="['notification', config.classes[currentType]]">
			<span class="notification__title">
				{{ $t(config.titles[currentType]) }}
			</span>
			<span class="notification__content" v-html="notification.text" />
		</div>
	</Transition>
</template>

<script>
export default {
	name: 'GlobalNotification',
	props: {
		time: {
			// time to show
			type: Number,
			default: 5000
		}
	},
	data() {
		return {
			timerId: null
		}
	},
	watch: {
		isActive() {
			if (this.isActive) {
				this.timerId = setTimeout(this.hide, this.time)
			}
		}
	},
	computed: {
		config() {
			return {
				titles: {
					'error': 'general.error',
					'success': 'general.success',
					'message': 'general.message'
				},
				classes: {
					'error': 'notification-error',
					'success': 'notification-success',
					'message': 'notification-message'
				}
			}
		},
		notification() {
			return this.$store.state.notification
		},
		currentType() {
			return this.$store.state.notification.type || types.INFO
		},
		isActive() {
			return this.$store.state.notification.isShow
		}
	},
	methods: {
		hide() {
			clearTimeout(this.timerId)
		
			this.$store.commit('hideNotif')
		}
	}
}
</script>

<style lang="scss">
.notification-enter-active {
	transition: all 0.3s ease-out;
}

.notification-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.notification-enter-from,
.notification-leave-to {
	transform: translateX(20px);
	opacity: 0;
}

.notification {
	position: fixed;
	right: 20px;
	top: 20px;
	max-width: 284px;
	width: 100%;
	padding: 15px;
	color: #000000;
	border-radius: 8px;
	z-index: 999999;

	&.notification-success {
		background: #dbfbe0;
	}

	&.notification-message {
		background: #e6e6e6;
	}

	&.notification-error {
		background: #fbdbdb;
	}

	&__title {
		display: block;
		width: 100%;
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
	}

	&__content {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}
}
</style>
