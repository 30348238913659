export default {
	GETOffer: {
		url: '/offer/:{offer_id}',
		routerKeys: ['offer_id'],
		type: 'get'
	},
	POSTAccountBrokerSignUp: {
		url: '/broker/signup',
		type: 'post',
		jsonKeys: ['email', 'password', 'password_confirmation']
	},
	POSTAccountBrokerSignIn: {
		url: '/broker/signin',
		type: 'post',
		jsonKeys: ['email', 'password', 'type']
	},
	POSTAccountAgencySignUp: {
		url: '/agency/signup',
		type: 'post',
		jsonKeys: ['email', 'password', 'password_confirmation']
	},
	POSTAccountAgencySignIn: {
		url: '/agency/signin',
		type: 'post',
		jsonKeys: ['email', 'password', 'type']
	},
	POSTAccountRequestCode: {
		url: '/account/auth/send-code',
		type: 'post',
		jsonKeys: ['account', 'login']
	},
	POSTAccountSignIn: {
		url: '/account/auth/signin',
		type: 'post',
		jsonKeys: ['account', 'login', 'code']
	},
	POSTAccountAgencyAddBroker: {
		url: '/agency/broker/add',
		type: 'post',
		queryKeys: ['Service-Lang', 'area-type', 'currency'],
		jsonKeys: ['broker_email', 'broker_password', 'name']
	},
	DELAccountAgencyDeleteBroker: {
		url: '/broker/:{slug}',
		type: 'delete',
		routerKeys: ['slug']
	},
	DELDeleteBrokerOfAgency: {
		url: '/agency/broker/:{slug}',
		type: 'delete',
		routerKeys: ['slug']
	},
	POSTAccountRecovery: {
		url: '/account/send/recover',
		type: 'post',
		jsonKeys: ['email']
	},
	POSTAccountLogout: {
		url: '/logout',
		type: 'post',
		jsonKeys: ['login']
	},
	POSTAccountChangePassword: {
		url: '/password/change',
		type: 'post',
		jsonKeys: ['password', 'new_password', 'new_password_confirmation']
	},
	// Broker
	GETProfileBroker: {
		url: '/broker/profile',
		type: 'get'
	},
	GETProfileBrokerBySlug: {
		url: '/broker/profile/:{slug}',
		type: 'get',
		routerKeys: ['slug']
	},
	POSTSetProfileBroker: {
		url: '/broker/profile',
		type: 'post',
		jsonKeys: [
			'name',
			'slug',
			'name_offer',
			'phone',
			'telegram',
			'instagram',
			'whatsapp',
			'position',
			'languages',
			'description',
			'links',
			'youtube',
			'subscription',
			'email'
		]
	},
	POSTSetProfileBrokerPhoto: {
		url: '/broker/profile/photo',
		type: 'post',
		formDataWrapper: true,
		jsonKeys: ['photo']
	},
	GETProfileAgency: {
		url: '/agency/profile',
		type: 'get'
	},
	POSTSetProfileAgency: {
		url: '/agency/profile',
		type: 'post',
		formDataWrapper: true,
		jsonKeys: ['website', 'logo', 'name']
	},
	POSTAssignSubscription: {
		url: '/agency/assign-subscription-to-agent',
		type: 'post',
		jsonKeys: ['subscription', 'agent']
	},
	GETProfileAllowedLanguages: {
		url: '/languages',
		type: 'get'
	},
	POSTSetBrokerArchive: {
		url: '/broker/:{slug}/archive',
		type: 'post',
		routerKeys: ['slug']
	},
	POSTSetBrokerUnarchive: {
		url: '/broker/:{slug}/unarchive',
		type: 'post',
		routerKeys: ['slug']
	},
	// Search
	GETSearchFilters: {
		url: '/search/filters',
		type: 'get'
	},
	GETSearchCoords: {
		url: '/search',
		type: 'get',
		pagination: true
	},
	GETSearchProjects: {
		url: '/search/projects',
		type: 'get',
		pagination: true
	},
	GETSearchUnits: {
		url: '/search/units',
		type: 'get',
		pagination: true
	},
	GETCheckActiveSubscription: {
		url: '/broker/is-active-subscription',
		type: 'get'
	},
	GETInfoProject: {
		url: '/search/projects/:{slug}',
		routerKeys: ['slug'],
		type: 'get'
	},
	GETOffers: {
		url: '/offers',
		type: 'get'
	},
	DELOffer: {
		url: '/offers/delete/:{id}',
		routerKeys: ['id'],
		type: 'delete'
	},
	POSTCreateOffer: {
		url: '/offers/create',
		type: 'post',
		jsonKeys: ['title', 'properties', 'theme_id', 'items', 'currency', 'units']
	},
	PATCHUpdateOffer: {
		url: '/offers/update',
		type: 'patch',
		jsonKeys: ['id', 'title', 'properties', 'theme_id', 'items', 'currency', 'units']
	},
	POSTSendPaymentData: {
		url: '/send/payment/data',
		type: 'post',
		jsonKeys: ['months', 'slots', 'slot_ids', 'amount']
	},
	POSTCalculatePaymentData: {
		url: '/calculate/payment/data',
		type: 'post',
		jsonKeys: ['months', 'slots']
	},
	GETTariffsList: {
		url: '/tariffs',
		type: 'get'
	}
}
