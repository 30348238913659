<template>
	<label class="main-radiobutton" :class="styleType">
		<div class="main-radiobutton__body">
			<span class="main-radiobutton__label checkbox-label" v-if="label">
				{{ label }}
			</span>
			<p v-if="subLabel">{{ subLabel }}</p>
		</div>
		<input
			type="radio"
			v-bind="$attrs"
			@input.stop.prevent="
				(event) => $emit('update:checked', event.target.checked)
			"
			class="main-radiobutton__input"
			hidden
		/>

		<span class="main-radiobutton__toggler checkbox-toggle">
			<svg
				width="13"
				height="9"
				viewBox="0 0 13 9"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M1 4L5 8L12 1" stroke="white" />
			</svg>
		</span>
	</label>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: null
		},
		subLabel: {
			type: String,
			default: null
		},
		styleType: {
			type: String,
			default: 'main'
		}
	},
	data() {
		return {
			id: this.$attrs.id || Math.random()
		}
	}
}
</script>

<style lang="scss">
.main-radiobutton {
	display: block;
	width: 18px;
	height: 18px;
	cursor: pointer;
	&__body {
		p {
			margin: 0;
			color: #c99d56;
			font-size: 12px;
			line-height: 16px;
		}
	}
	&__toggler {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 18px;
		height: 18px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		transition: all 0.3s linear;
		border-radius: 50%;
	}

	input:checked ~ .checkbox-toggle {
		background-color: #c99d56;
		transition: all 0.3s linear;
	}

	&.main {
		display: flex;
		width: 100%;
		justify-content: space-between;

		.checkbox-label {
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			color: #808080;
		}
	}
}
</style>
