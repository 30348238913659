<template>
	<div class="broker-menu">
		<router-link
			:to="{
				name: 'BrokerSearch',
				params: { lang: $store.state.uiLanguage }
			}"
			class="link"
		>
			<or-icon type="project" />
			Projects
		</router-link>
		<router-link
			class="link"
			:to="{
				name: 'CreateOffer',
				params: { lang: $store.state.uiLanguage }
			}"
		>
			<or-icon type="property" />
			<span>
				{{ unitsCount ? 'Create offer' : 'Offers' }}
				<span v-if="unitsCount"> ({{ unitsCount }}) </span>
			</span>
		</router-link>
		<router-link
			class="link"
			:to="{
				name: 'BrokerProfile',
				params: { lang: $store.state.uiLanguage }
			}"
		>
			<or-icon type="profile" />
			Profile
		</router-link>
	</div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const unitsCount = computed(() => store.state.broker.offer.units.length)
</script>
<style scoped lang="scss">
.broker-menu {
	position: fixed;
	z-index: 9999;
	bottom: -1px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	width: 100%;
	left: 0;
	right: 0;
	padding: 10px;
	display: none;
	align-items: flex-end;
	background: white;
	justify-content: space-around;
	.link {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		color: #808080;
		font-size: 12px;
		i {
			font-size: 18px;
			color: #c99d56;
		}
	}
	@media screen and (max-width: 768px) {
		display: flex;
	}
}
</style>
