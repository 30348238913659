export default {
	GETPlaceTypes: {
		url: '/place/global/lists/announcement/types',
		type: 'get',
		pagination: true
	},
	GETSearch: {
		url: '/place/search',
		type: 'get',
		queryKeys: [
			'region',
			'price_from',
			'price_to',
			'type_id',
			'city_id',
			'bathroom_count',
			'bedroom_count',
			'stage_id',
			'furnished',
			'pr_slug',
			'status',
			'order_by',
			'direction',
			'relate_to',
			'city',
			'phrases[0]',
			'phrases[1]',
			'phrases[2]',
			'phrases[3]',
			'phrases[4]',
			'phrases[5]',
			'phrases[6]',
			'phrases[7]',
			'phrases[8]',
			'phrases[9]',
			'phrases[10]',
			'phrases[11]',
			'phrases[12]',
			'phrases[13]',
			'phrases[14]',
			'area_from',
			'area_to',
			'points',
			'slugs',
			'-ann',
			'-prj',
			'-bldr',
			'squ',
			'has_parking',
			'with_balcony',
			'year_const'
		],
		pagination: true
	},
	GETAdStatuses: {
		url: '/place/statuses',
		type: 'get'
	},
	GETADSType: {
		url: '/place/global/lists/announcement/types',
		type: 'get'
	},
	GETStagesBuilding: {
		url: '/place/global/lists/announcement/stages',
		type: 'get'
	},
	GETADSCoords: {
		url: '/place/search_map',
		type: 'get',
		queryKeys: [
			'region',
			'price_from',
			'price_to',
			'type_id',
			'city_id',
			'bathroom_count',
			'bedroom_count',
			'stage_id',
			'furnished',
			'status',
			'city',
			'order_by',
			'pr_slug',
			'direction',
			'phrases[0]',
			'phrases[1]',
			'phrases[2]',
			'phrases[3]',
			'phrases[4]',
			'phrases[5]',
			'phrases[6]',
			'phrases[7]',
			'phrases[8]',
			'phrases[9]',
			'phrases[10]',
			'phrases[11]',
			'phrases[12]',
			'phrases[13]',
			'phrases[14]',
			'area_from',
			'area_to',
			'lat',
			'lng',
			'radius',
			'-ann',
			'-prj',
			'-bldr',
			'squ',
			'has_parking',
			'with_balcony',
			'year_const'
		],
		pagination: true
	},
	GETSearchRegions: {
		url: '/place/city_regions/regions',
		type: 'get',
		queryKeys: ['city_slug', 'region_slug', 'title', 'order_by', 'direction'],
		pagination: true
	},
	POSTSendRequest: {
		url: '/place/request',
		type: 'post',
		jsonKeys: ['name', 'phone', 'type', 'type_id', 'url']
	},
	GETRegionList: {
		url: '/place/city_regions',
		type: 'get',
		pagination: true,
		queryKeys: ['page', 'per-page', 'order_by', 'direction']
	},
	GETCityList: {
		url: '/place/city_regions/cities',
		type: 'get',
		queryKeys: ['page', 'per-page']
	},
	GETTypeAds: {
		url: '/place/global/lists/announcement/types',
		type: 'get'
	},
	GETComplexList: {
		url: '/place/complexes',
		type: 'get',
		pagination: true,
		jsonKeys: ['page', 'per-page', 'project_slug']
	},
	GETComplex: {
		url: '/place/complexes/:{slug}',
		routerKeys: ['slug'],
		type: 'get'
	},
	GETInfoAds: {
		url: '/place/show/:{slug}',
		routerKeys: ['slug'],
		type: 'get',
		jsonKeys: ['page', 'per-page']
	},
	GETDevelopers: {
		url: '/place/developers',
		type: 'get',
		queryKeys: ['order_by', 'direction', 'slug', 'search'],
		pagination: true
	},
	GETProjectList: {
		url: '/place/projects',
		type: 'get',
		queryKeys: [
			'page',
			'per_page',
			'developer_slug',
			'region_slug',
			'slug',
			'search',
			'order_by',
			'direction'
		]
	},
	GETPlaceCurrency: {
		url: '/place/currency',
		type: 'get'
	},
	GETInfoProject: {
		url: '/place/projects/:{slug}',
		routerKeys: ['slug'],
		type: 'get',
		queryKeys: ['page', 'per-page']
	},
	GETAdsSearch: {
		url: '/place/search',
		type: 'get',
		queryKeys: ['page', 'per-page', 'complex', 'city_id']
	},
	GETSearchFilters: {
		url: '/place/search/filters',
		type: 'get'
	},
	GETDevelopersInfo: {
		url: '/place/developers/:{slug}',
		routerKeys: ['slug'],
		type: 'get',
		queryKeys: ['page', 'per-page']
	},
	GETAreaInfo: {
		url: '/place/city_regions/city/region/:{slug}',
		routerKeys: ['slug'],
		type: 'get',
		queryKeys: ['page', 'per-page']
	},
	GETFooter: {
		url: 'place/footer',
		type: 'get'
	}
}
