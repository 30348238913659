<template>
	<footer class="footer" :class="className">
		<div class="container">
			<Links
				v-if="!isBroker"
				class="footer__links"
				:content="links"
				:is-load="isLoad"
			/>
			<Information :is-broker="isBroker" class="footer__information" />
		</div>
	</footer>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import Information from './components/information.vue'

const Links = defineAsyncComponent(() => import('./components/links.vue'))

export default {
	name: 'Footer',
	props: {
		isBroker: {
			type: Boolean,
			default: false
		}
	},
	components: { Links, Information },
	data() {
		return {
			linkInfo: {},
			isLoad: false
		}
	},
	computed: {
		links() {
			return [
				{
					title: this.$t('general.areas'),
					list: this.linkInfo.places,
					type: 'areas'
				},
				{
					title: this.$t('general.developers'),
					list: this.linkInfo.developers,
					type: 'developer'
				},
				{
					title: this.$t('mobile.complex_kinds_housing'),
					list: this.linkInfo.housing_types,
					type: 'search'
				},
				{
					title: this.$t('general.footerAbout'),
					list: [
						{
							title: this.$t('general.footerContacts'),
							slug: 'Contacts'
						},
						{
							title: this.$t('titlePages.payment'),
							slug: 'Payment'
						}
					],
					type: 'custom'
				}
			]
		},
		className() {
			return {
				broker: this.isBroker,
				search: this.$route.name === 'BrokerSearch',
				agency: !this.$store.getters.isBroker
			}
		}
	},
	beforeMount() {
		createRequest(requestConfigs.GETFooter, {})
			.then((result) => (this.linkInfo = result.response))
			.finally(() => (this.isLoad = true))
	}
}
</script>

<style lang="scss">
.footer {
	padding: 70px 0 42px;
	background-color: #f5f5f5;
	@media screen and (max-width: 535px) {
		padding: 30px 0;
	}
	&.broker {
		@media screen and (max-width: 768px) {
			padding: 70px 0 140px;
		}
	}
	&.search {
		@media screen and (max-width: 768px) {
			padding: 70px 0 180px;
		}
	}
	&.broker.agency {
		@media screen and (max-width: 768px) {
			padding: 70px 0 100px;
		}
	}
}
</style>
