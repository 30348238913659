<template>
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle
			cx="16"
			cy="16"
			r="15.5"
			transform="rotate(-180 16 16)"
			stroke="#E4E4E4"
		/>
		<path
			d="M18.3403 16.0006L13.6047 11.3017C13.3706 11.0694 13.3706 10.6928 13.6047 10.4605C13.8388 10.2282 14.2184 10.2282 14.4525 10.4605L19.5393 15.5078C19.6748 15.6423 19.7319 15.8253 19.7104 16.0006C19.7319 16.1758 19.6748 16.3588 19.5393 16.4933L14.4525 21.5406C14.2184 21.7729 13.8388 21.7729 13.6047 21.5406C13.3706 21.3083 13.3706 20.9317 13.6047 20.6994L18.3403 16.0006Z"
			fill="#2A3043"
		/>
	</svg>
</template>
