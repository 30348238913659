<template>
	<div class="add-exeption">
		<or-input v-model="title" class="main-type" icon-before="search" />

		<Transition name="exeptions-fade">
			<ul v-if="list.length" class="add-exeption__content">
				<li v-for="item in list" :key="item.id">
					<or-checkbox
						:label="item.title"
						:checked="selected.includes(item.slug)"
						@update:checked="select(item, $event)"
					/>
				</li>
			</ul>
		</Transition>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'

import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

export default {
	props: {
		type: {
			// developers / projects
			type: String,
			required: true
		}
	},
	data() {
		return {
			title: null,
			loading: false,
			selected: [],
			list: []
		}
	},
	watch: {
		title() {
			if (this.title?.length > 2) {
				this.search()
			} else {
				this.list = []
			}
		}
	},
	computed: {
		config() {
			return {
				request:
					this.type === 'developers'
						? requestConfigs.GETDevelopers
						: requestConfigs.GETProjectList
			}
		},
		selectedList() {
			return this.$store.state.exeptions[this.type]
		}
	},
	methods: {
		select(item, checked) {
			if (checked) {
				this.selected.push(item.slug)

				this.$store.commit('addToExeptions', {
					type: this.type,
					slug: item.slug
				})
			} else {
				this.selected.splice(this.selected.indexOf(item.slug), 1)
				this.$store.commit('removeFromExeptions', {
					type: this.type,
					value: item
				})
			}
		},
		search: debounce(function () {
			if (this.loading) {
				return
			}

			this.loading = true

			createRequest(this.config.request, {
				queryPayload: {
					per_page: 10,
					page: 1,
					search: this.title
				}
			})
				.then(({ response }) => {
					this.list = []
					this.list.push(...response.items)
				})
				.finally(() => {
					this.loading = false
				})
		}, 500)
	}
}
</script>

<style lang="scss">
.add-exeption {
	padding: 20px 0;
	background-color: #ffffff;

	&__content {
		margin-top: 15px;
		max-height: 200px;
		overflow-y: auto;

		li {
			padding: 10px;
		}
	}
}
</style>
