<template>
	<div v-click-away="outside" ref="dropdown" class="dropdown" :class="kind">
		<div @click.stop="toggle" class="dropdown__toggle dropdown-tgl">
			<slot name="toggle" :active="isShow">
				<button type="button">close</button>
			</slot>
		</div>
		<transition
			:name="animation"
			@mouseleave="removeNoScroll"
			@mouseover="addNoScroll"
		>
			<div
				ref="dropdownList"
				v-if="isShow"
				class="dropdown__content dropdown-content"
			>
				<slot name="content" :active="isShow" />
			</div>
		</transition>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus'
import vClickAway from '@/directives/vClickAway'
import { mapState } from 'vuex'
import { getScrollWidth } from '@/utils/getScrollWidth'
import { nextTick } from 'vue'
const clickOutside = {
	beforeMount(el, binding) {
		el.clickOutsideEvent = function (event) {
			if (!(el === event.target || el.contains(event.target))) {
				binding.value(event, el)
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent, {
			passive: true
		})
	},
	unmounted(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	}
}
export default {
	props: {
		animation: {
			type: String,
			default: 'slide-fade'
		},
		kind: {
			type: String,
			default: ''
		},
		hideAnotherDropDown: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		type: String
	},
	directives: {
		'click-away': vClickAway,
		'click-outside': clickOutside
	},
	data() {
		return {
			isShow: false,
			isDropdownRight: Boolean
		}
	},
	watch: {
		isShow: function () {
			this.$emit('update', this.isShow)
		},
		'$route.name': function () {
			this.hide()
		}
	},
	computed: {
		...mapState(['scrollWidth'])
	},
	methods: {
		toggle(e) {
			e.stopPropagation()
			if (this.disabled) {
				return
			}
			// Когда мы пытаемся открыть, то надо закрыть все остальные открытые
			if (this.hideAnotherDropDown && !this.isShow) {
				eventBus.trigger('dropdown-hide')
			}
			this.isShow = !this.isShow
			if (this.type === 'search' && this.isShow) {
				this.getScrollWidth()
			}
			nextTick(() => {
				this.dropdownPosition()
			})
		},
		outside() {
			this.hide()
		},
		hide() {
			this.isShow = false
		},
		addNoScroll() {
			if (this.type !== 'search') return
			document.body.style.overflow = 'hidden'
			document.body.style.paddingRight = `${this.scrollWidth}px`
		},
		removeNoScroll() {
			if (this.type !== 'search') return
			document.body.style.overflow = 'auto'
			document.body.style.paddingRight = `unset`
		},
		getScrollWidth,
		dropdownPosition() {
			const dropdownList = this.$refs.dropdownList
			if (dropdownList) {
				const rect = dropdownList.getBoundingClientRect()
				this.isDropdownRight = rect.right < window.innerWidth
				if (!this.isDropdownRight) {
					dropdownList.classList.add('dropdown-right')
				} else {
					dropdownList.classList.remove('dropdown-right')
				}
			}
		}
	},
	beforeMount() {
		eventBus.on('dropdown-hide', this.hide)
		window.addEventListener('resize', this.hide)
	},
	unmounted() {
		eventBus.off('dropdown-hide', this.hide)
	}
}
</script>

<style lang="scss">
.dropdown {
	position: relative;
	&__toggle {
		cursor: pointer;
	}
	&__content {
		position: absolute;
		left: 0;
		margin: 0 auto;
		z-index: 9999;
		transition: all 0.2s ease;
		min-width: 100%;
		&.dropdown-right {
			right: 0;
			left: unset;
		}
	}
	input {
		cursor: pointer;
	}
	// Animation
	.slide-fade-enter-active {
		transition: all 0.2s linear;
	}
	.slide-fade-leave-active {
		transition: all 0.2s linear;
	}
	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(-10px);
		opacity: 0;
	}
	// kinds
	&.answer {
		.dropdown-content {
			position: static;
		}
	}
}
@media screen and (max-width: 500px) {
	.search-guests {
		.dropdown {
			&__content {
				margin-top: 15px;
				left: 50%;
				right: unset;
				transform: translateX(-50%);
			}
		}
	}
}
</style>
