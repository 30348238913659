<template>
	<div
		class="boots-slider"
		@mouseleave="focused = false"
		@mouseover="focused = true"
		@mousedown.prevent="(event) => mouseStart(event)"
		@touchstart="touchStart"
	>
		<div
			ref="list"
			:style="{ transform: `translateX(${translateX}px)` }"
			class="boots-slider__wrapper"
		>
			<div
				v-for="(item, index) in collectionData"
				:key="index"
				ref="slides"
				class="boots-slider__slide"
				@click="cardClick($event, item, index)"
        @touchstart="(e) => clickEmulate(e, item, index)"
			>
				<slot :item="item" :index="index"></slot>
			</div>
		</div>
		<!--   стрелки управления слайдером-->
		<div
			v-if="props.sliderProperties.pagination && !isMobile"
			class="boots-slider__pagination"
		>
			<div
				:class="{ disabled: currentIndex === 0 }"
				class="boots-slider__btn boots-slider__btn--prev"
				@pointerdown.prevent="handleKeydown('ArrowLeft')"
			>
				<GalleryBtn />
			</div>
			<div
				:class="{
					disabled:
						currentIndex >= swipeRightBorder ||
						collectionLength <= slidesPerView
				}"
				class="boots-slider__btn"
				@pointerdown.prevent="handleKeydown('ArrowRight')"
			>
				<GalleryBtn />
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted } from '@vue/runtime-core'
import { onUnmounted, ref, watch } from 'vue'
import GalleryBtn from '@/components/svg/GalleryBtn'
// import AdditiveLoadingCollection from '~/utils/AdditiveLoadingCollection'

const props = defineProps({
	sliderProperties: {
		type: Object,
		default() {
			return { pagination: false }
		}
	},
	collectionData: Array
})

const emit = defineEmits(['cardClick'])

const list = ref()
const slides = ref()

let focused = ref(false)

let windowWidth = ref(0)
let isMobile = ref(false)
let isMouseClick = ref(false)
let isTouchAction = ref(false)

const gap = 20
const padding = `${gap / 2}px`

function updateWidth() {
	windowWidth.value = window.innerWidth

	if (windowWidth.value < 991) {
		isMobile.value = true
		// if (list.value) list.value.style.overflow = 'auto clip'
		// if (list.value) list.value.style.transition = 'unset'
		currentIndex.value = 0
		translateX.value = 0
	} else {
		isMobile.value = false
		// if (list.value) list.value.style.transition = 'all 0.4s ease-in-out'
		// if (list.value) list.value.style.overflow = 'unset'
	}
}

// количество слайдов, которые умещаются в ширину экрана
let slidesPerView = ref(0)
// индекс текущего слайда
let currentIndex = ref(0)
// смещение слайдера по X
let translateX = ref(0)
// ширина слайдера
let listWidth = ref(0)
// ширина слайда
let slideWidth = ref(0)
// полное количество слайдов
let collectionLength = ref(0)
// количество слайдов, которые умещаются в пройденное курсором расстояние
let slidesCount = ref(0)
//"правая" граница возможности прокрутки стрелками в зависимости от количества слайдов
let swipeRightBorder = ref(0)
// параметры touch
let touchParams = { startX: ref(0), endX: ref(0) }
let previousTouch
// параметр, отвечающий за дозагрузку слайдов. За {slidesCountFromEnd} слайдов до конца произойдет подзагрузка.
const slidesCountFromEnd = 1
// возможность прокрутки
let isScrolled = ref(false)
// проверка на "клик" или "скролл"
let isClick = ref(true)

let listResizeObserver

// только для мобильной версии
//***********
// расстояние,на которое проскроллен слайдер
let sliderScrolled = ref(0)
// разница между шириной родительского элемента и шириной слайдера
let containerDifference = ref(0)
//***********

function clickEmulate(e, item, index) {
  if (e.touches.length > 1) return

  const touch = e.touches[0];
  const callback = (e) => {
    if (e.changedTouches.length === 1) {
      const startX = touch.clientX
      const startY = touch.clientY
      const endTouch = e.changedTouches[0]
      const endX = endTouch.clientX
      const endY = endTouch.clientY
      if (Math.sqrt((endX - startX)**2 + (endY - startY)**2) <= 10) {
        emit('cardClick', e, item, index)
      }
    }
    window.removeEventListener('touchend', callback)
  }
  window.addEventListener('touchend', callback)
}

function calcSlideWidth(index) {
	if (slides.value && slides.value[index]) {
		// получение ширины слайда
		slideWidth.value = slides.value[index].clientWidth
		// получение количества слайдов
		collectionLength.value = slides.value.length
		//прокрутка возможна, если количество слайдов превышает количетсво видимых слайдов
		isScrolled.value = collectionLength.value > slidesPerView.value
	}
}

function validateParams() {
	//макисмальное значение сдвига слайдера
	// добавляем {gap}, так как у первого и последнего слайдов
	// нет паддинга слева и справа соответственно
	let maxTranslateX =
		collectionLength.value * slideWidth.value -
		slideWidth.value * slidesPerView.value +
		gap
	//пограничные значения смещения
	if (translateX.value < -maxTranslateX) {
		translateX.value = -maxTranslateX
	} else if (translateX.value > 0) {
		translateX.value = 0
	}
	//пограничные значения текущего индекса слайда
	if (currentIndex.value > collectionLength.value - 1) {
		currentIndex.value = collectionLength.value - 1
	} else if (currentIndex.value > swipeRightBorder.value) {
		currentIndex.value = swipeRightBorder.value
	} else if (currentIndex.value < 0) {
		currentIndex.value = 0
	}
}

function calcSwipeRightBorder() {
	//расчет "правой" границы возможности прокрутки стрелками в зависимости от количества слайдов,
	// т.е прокрутка слайдера не возможна при определенном текущем инедексе,
	// сделано для того, чтобы слайды всегда заполняли всю ширину контейнера
	return (swipeRightBorder.value =
		collectionLength.value > slidesPerView.value
			? collectionLength.value - Math.floor(slidesPerView.value)
			: collectionLength.value - 1)
}

function cardClick(e, payload, index) {
	if (!isClick.value) return
	emit('cardClick', e, payload, index)
}

// управление слайдером
function swipe(direction, slidesCount) {
	if (list.value) list.value.style.transition = 'all 0.4s ease-in-out'
	calcSlideWidth(currentIndex.value)
	calcSwipeRightBorder()
	// расчет текущего слайда по направлению вперед
	if (direction === 'forward') {
		currentIndex.value += slidesCount
	}
	// расчет текущего слайда по направлению назад
	if (direction === 'back') {
		currentIndex.value -= slidesCount
	}
	translateX.value = -(slideWidth.value * currentIndex.value)
	validateParams()
}

function mouseMove(event) {
	if (!isScrolled.value) return
	translateX.value += event.movementX
}

function mouseEnd(event) {
	touchParams.endX.value = event.clientX
	//снимаем слушатели
	if (typeof window !== 'undefined') {
		window.removeEventListener('mouseup', mouseEnd)
		window.removeEventListener('mousemove', mouseMove)
	}
	//проверяем наше действие мышью - клик это или скролл
	isClick.value =
		Math.abs(touchParams.endX.value - touchParams.startX.value) <= 3
	// добавлаем стили для анимации, чтобы был плавный эффект "докрутки" сладйера после того,как отпустили кнопку мыши
	if (list.value) list.value.style.transition = 'all 0.4s ease-in-out'
	if (list.value) list.value.style.cursor = 'pointer'

	if (!isScrolled.value) return

	//полное расстояние, пройденное мышью
	let mouseTranslateX = Math.abs(
		touchParams.endX.value - touchParams.startX.value
	)
	// расчет количества слайдов, которые умещаются в пройденное курсором расстояние
	slidesCount.value = Math.ceil(mouseTranslateX / slideWidth.value)
	// определение направления прокрутки слайдера
	if (touchParams.endX.value < touchParams.startX.value) {
		swipe('forward', slidesCount.value)
	} else {
		swipe('back', slidesCount.value)
	}
}

function mouseStart(event) {
	//если клик мыши, выставляюм соответствующие значения флагам и убираем css скролл
	// прокрутка работает за счет функционала, реализованного в этом компоненте
	isMouseClick.value = true
	isTouchAction.value = false
	if (isMouseClick.value) {
		if (list.value) list.value.style.overflow = 'unset'
		if (list.value && isScrolled.value) list.value.style.cursor = 'grab'
	}
	//начальное положение мыши
	touchParams.startX.value = event.clientX
	//сбрасываем конечное положение мыши
	touchParams.endX.value = 0
	// убираем стили для анимации, чтобы ее не было при прокрутке мышью
	if (list.value) list.value.style.transition = 'unset'
	//вешаем слушатели: когда кнопка мышки отпущена и ее движение
	if (typeof window !== 'undefined') {
		window.addEventListener('mouseup', mouseEnd, { passive: true })
		window.addEventListener('mousemove', mouseMove, { passive: true })
	}
}
function touchStart() {
	//если touch касание, выставляюм соответствующие значения флагам и добавляем css скролл
	//прокрутка работает за счет overflow
	isMouseClick.value = false
	isTouchAction.value = true
	if (isTouchAction.value) {
		if (list.value) list.value.style.overflow = 'auto clip'
		if (list.value) list.value.style.transition = 'unset'
	}
}

function handleKeydown(e) {
	// if (isMobile.value) return
	if (!focused.value) return
	if (!isScrolled.value) return
	switch (e.key || e) {
		case 'ArrowLeft':
			if (currentIndex.value !== 0) swipe('back', 1)
			break
		case 'ArrowRight':
			if (currentIndex.value !== swipeRightBorder.value) swipe('forward', 1)
			break
	}
}

watch(
	() => props.collectionData,
	(collectionData) => {
		// для компонентов с табами (Rooms, Layout) необходимо перерисовывать слайдер
		// поэтому некоторые параметры пересчитываются и обнуляются,
		// для правильного отображения и корректной работы элементов контроля
		collectionLength.value = collectionData?.length
		isScrolled.value = collectionLength.value > slidesPerView.value
		if (list.value) list.value.style.transition = 'unset'
		currentIndex.value = 0
		translateX.value = 0

		calcSwipeRightBorder()
	},
	{ deep: true }
)

onMounted(async () => {
	//получаем ширину экрана для определения соответсвия разрешения устройству(мобильное или десктопное)
	updateWidth()
	//следим за нажатием клавиш на клавиатуре
	if (typeof window !== 'undefined') {
		window.addEventListener('keydown', handleKeydown, { passive: true })
		window.addEventListener('resize', updateWidth, { passive: true })
	}

	if (slides.value) collectionLength.value = slides.value.length
	if (slidesPerView.value)
		isScrolled.value = collectionLength.value > slidesPerView.value
	calcSwipeRightBorder()
	//обсервер отвечает за актуальные значения параметров слайдера (ширина, количество слайдов на странице, возможность скролла)
	listResizeObserver = new ResizeObserver((entry) => {
		entry.forEach((entry) => {
			listWidth.value = entry.contentRect.width
			if (slides.value && slides.value[0]) {
				slideWidth.value = slides.value[0].clientWidth
				slidesPerView.value = Math.max(listWidth.value / slideWidth.value, 1)
				isScrolled.value = collectionLength.value > slidesPerView.value
			}
		})
	})
	listResizeObserver.observe(list.value)
})
onUnmounted(() => {
	listResizeObserver.disconnect()
	if (typeof window !== 'undefined') {
		window.removeEventListener('keydown', handleKeydown)
		window.removeEventListener('resize', updateWidth)
	}
})
</script>

<style lang="scss">
.boots-slider {
	overflow: hidden;
	width: 100%;

	&__wrapper {
		display: flex;
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__slide {
		padding-right: v-bind(padding);
		padding-left: v-bind(padding);
		box-sizing: content-box;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
		& > div {
			cursor: unset;
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
		grid-gap: 10px;
	}

	&__btn {
		width: 32px;
		height: 32px;
		cursor: pointer;

		&--prev {
			transform: rotate(180deg);
		}

		&:disabled svg path {
			fill: #e4e4e4;
		}
		&.disabled {
			pointer-events: none;
			opacity: 0.2;
		}
	}
}
</style>
