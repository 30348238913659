const _clickEventType = () => (document.ontouchstart !== null ? 'click' : 'touchstart');

function _clickAway(event, target, callback) {
  if (!target.contains(event.target)) callback();
}

const EL_CALLBACK_STORE = '__click_away_callback__';

const vClickAway = {
  mounted(el, binding) {
    el[EL_CALLBACK_STORE] = (e) => _clickAway(e, el, binding.value);
    document.addEventListener(_clickEventType(), el[EL_CALLBACK_STORE]);
  },

  beforeUnmount(el) {
    document.removeEventListener(_clickEventType(), el[EL_CALLBACK_STORE]);
  }
};

export default vClickAway;