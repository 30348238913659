<template>
	<div class="exeptions-list" @scroll.passive="handleScroll">
		<button
			type="button"
			v-for="(item, index) in list"
			:key="index"
			class="btn exeptions-list__btn"
		>
			{{ item.title }}
			<or-icon @click="removeFromExeptions(item)" type="close" />
		</button>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'

import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

const requests = {
	projects: requestConfigs.GETProjectList,
	developers: requestConfigs.GETDevelopers
}

export default {
	props: {
		type: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			list: [],
			loading: false,
			meta: {
				per_page: 10,
				current_page: 0
			}
		}
	},
	async beforeMount() {
		await this.get()
	},
	methods: {
		handleScroll: debounce(async function (evt) {
			const scrollElement = evt.target

			if (
				scrollElement?.scrollTop + scrollElement?.clientHeight >=
				scrollElement?.scrollHeight - 1
			) {
				if (this.loading) {
					return
				}

				// Проверяем остались ли посты
				if (this.meta.current_page < (this.meta.last_page || 0)) {
					await this.get(true)
				}
			}
		}, 300),
		async get(more) {
			this.loading = true
			this.meta.current_page += 1

			createRequest(requests[this.type], {
				queryPayload: {
					slug: this.slugs
				}
			})
				.then(({ response }) => {
					if (!more) {
						this.list.length = 0
					}

					this.list.push(...response.items)
					this.meta.last_page = this.lastPage
				})
				.finally(() => {
					this.loading = false
				})
		},
		removeFromExeptions(payload) {
			this.$store.commit('removeFromExeptions', {
				type: this.type,
				slug: payload.slug
			})

			this.list = this.list.filter((itm) => itm.slug !== payload.slug)

			this.$store.commit('showNotif', {
				text: this.$t('notification.successRemoveFromExeption'),
				type: 'success'
			})
		}
	},
	computed: {
		slugs() {
			return this.content
				.slice(
					this.meta.per_page * (this.meta.current_page - 1),
					this.meta.per_page * this.meta.current_page || 1
				)
				.join(',')
		},
    lastPage() {
      // Делим общее количество объектов на количество объектов на странице и округляем в большую сторону
      return Math.ceil(this.content.length / this.meta.per_page)
    },
		content() {
			return this.$store.state.exeptions[this.type]
		}
	}
}
</script>

<style lang="scss">
.exeptions-list {
	max-height: 300px;
	min-height: 300px;
	overflow-y: auto;

	&__btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 40px;
		padding: 10px !important;
		font-weight: 400;
		text-align: left;
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;
		background: #c99d56 !important;
		cursor: default;

		i {
			cursor: pointer;
		}
	}
}
</style>
