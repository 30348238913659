<template>
	<div class="company-modal">
		<div class="company-modal__body">
			<company-free-slot :subscription="content.subscription" />
			<div class="company-modal__move-to">
				<span></span>
				{{ $t('web.move_to') }}
				<span></span>
			</div>
			<template v-if="content.agents.length === 0">
				<company-no-slot />
				<or-button @click="showAddAgentModal" :height="48">
					{{ $t('web.change_plan') }}
				</or-button>
			</template>
			<template v-else>
				<company-agent
					v-for="agent in content.agents"
					:key="agent.slug"
					:agent="agent"
					@click="() => assignSubscription(agent.slug)"
				/>
			</template>
		</div>
		<!--		<or-button class="w-180 company-modal__btn"> Button </or-button>-->
	</div>
</template>
<script>
import CompanyAgent from '@/components/modals/CompanyOffice/components/CompanyAgent.vue'
import CompanyFreeSlot from '@/components/modals/CompanyOffice/components/CompanyFreeSlot.vue'
import CompanyNoSlot from '@/components/modals/CompanyOffice/components/CompanyNoSlot.vue'
import OrButton from '@/components/global/orButton.vue'

export default {
	name: 'CompanyModal',
	components: { OrButton, CompanyNoSlot, CompanyFreeSlot, CompanyAgent },
	props: {
		content: Object
	},
	methods: {
		assignSubscription(slug) {
			this.content.callback(this.content.subscription.id, slug)
			this.$store.commit('hideModal')
		},
		showAddAgentModal() {
			this.$store.commit('hideModal')
			this.content.addAgentModal()
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/components/modal/company-modal';
</style>
