import axios from 'axios'
import store from '@/store'
import router from '@/router/index'
import { money } from '@/enums/moneyСollections'
import { squarePayloadText } from '@/enums/areaCollections'

const backendMain = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
	timeout: 20000,
	headers: {
		Accept: 'application/json'
	}
})

backendMain.interceptors.request.use(
	(config) => {
		config.headers.common = {
			...config.headers.common,
			'Service-Lang': store.state.uiLanguage,
			currency: money[store.state.activeMoney],
			'area-type': squarePayloadText[store.state.areaUnit]
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

backendMain.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		throw error
	}
)

// TODO: Если один запрос 401, то остальные можно закрывать
// const controller = new AbortController()
const backendBroker = axios.create({
	baseURL: `${process.env.VUE_APP_API_BROKER_URL}/api`,
	timeout: 20000,
	headers: {
		Accept: 'application/json',
		'Accept-Language': 'en,ru'
	}
})

backendBroker.interceptors.request.use(
	(config) => {
		config.headers.common = {
			...config.headers.common,
			'Service-Lang': store.state.uiLanguage,
			currency: money[store.state.activeMoney],
			'area-type': squarePayloadText[store.state.areaUnit]
		}

		if (store.state.broker.token) {
			config.headers.common[
				'Authorization'
			] = `Bearer ${store.state.broker.token}`
		}

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

backendBroker.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response?.status === 401) {
			store.commit('logout')
			router.push({
				name: 'Login',
				params: {
					lang: store.state.uiLanguage
				}
			})

			return
		}

		throw error
	}
)

export { backendMain, backendBroker }
