const routes = [
	{
		path: '/:lang?',
		name: 'App',
		children: [
			{
				path: '',
				name: 'MainRoute',
				component: () => import('../views/Home.vue')
			},
			{
				path: 'search',
				name: 'Search',
				component: () => import('../views/Search.vue')
			},
			{
				path: 'developers',
				name: 'Developers',
				component: () => import('../views/Developers.vue')
			},
			{
				path: 'areas',
				name: 'Areas',
				component: () => import('../views/Areas.vue')
			},
			{
				path: 'complexes',
				name: 'Complexes',
				component: () => import('../views/Complexes.vue')
			},
			{
				path: 'terms',
				name: 'Terms',
				component: () => import('../views/Terms.vue'),
				meta: { scrollTop: true }
			},
			{
				path: 'developers/:slug',
				name: 'SoloDeveloper',
				component: () => import('../views/SoloDeveloper.vue')
			},
			{
				path: 'areas/:slug',
				name: 'SoloArea',
				component: () => import('../views/SoloArea.vue')
			},
			{
				path: 'ads/:slug',
				name: 'SoloAds',
				component: () => import('../views/SoloAds')
			},
			{
				path: 'complexes/:slug',
				name: 'SoloComplex',
				component: () => import('../views/SoloComplex')
			},
			{
				path: 'projects/:slug',
				name: 'SoloProject',
				component: () => import('../views/SoloProject')
			},
			{
				path: 'contacts',
				name: 'Contacts',
				component: () => import('../views/Contacts.vue'),
				meta: { scrollTop: true }
			},
			{
				path: 'payment',
				name: 'Payment',
				component: () => import('../views/Payment.vue'),
				meta: { scrollTop: true }
			},
			{
				path: 'privacy',
				name: 'Privacy',
				component: () => import('../views/Privacy.vue'),
				meta: { scrollTop: true }
			},
			{
				path: 'cookies',
				name: 'Cookies',
				component: () => import('../views/Cookies.vue'),
				meta: { scrollTop: true }
			},
			{
				path: 'favourites',
				name: 'Favourites',
				component: () => import('../views/Favourites.vue')
			},
			{
				path: 'projects',
				name: 'Projects',
				component: () => import('../views/Projects.vue')
			},
			// New pages
			{
				path: 'offer/:offer_id',
				name: 'BrokerOffer',
				component: () => import('../views/Broker/Offer.vue')
			},
			{
				path: 'type-broker-account/login',
				name: 'Login',
				component: () => import('../views/Broker/Login/Login.vue'),
				meta: {
					showNotAuth: true
				}
			},
			{
				path: 'type-broker-account/agent-register',
				name: 'AgentRegister',
				component: () =>
					import('../views/Broker/Registration/AgentRegister.vue'),
				meta: {
					showNotAuth: true
				}
			},
			{
				path: 'type-broker-account/agency-register',
				name: 'AgencyRegister',
				component: () =>
					import('../views/Broker/Registration/AgencyRegister.vue'),
				meta: {
					showNotAuth: true
				}
			},
			{
				path: 'type-broker-account/agent-recovery',
				name: 'AgentRecovery',
				component: () => import('../views/Broker/Recovery/AgentRecovery.vue'),
				meta: {
					showNotAuth: true
				}
			},
			{
				path: 'type-broker-account/agency-recovery',
				name: 'AgencyRecovery',
				component: () => import('../views/Broker/Recovery/AgencyRecovery.vue'),
				meta: {
					showNotAuth: true
				}
			},
			{
				path: 'broker-profile',
				name: 'BrokerProfile',
				component: () => import('../views/Broker/Profile.vue'),
				meta: {
					brokerAuth: true
				}
			},
			{
				path: 'agent-profile/:slug',
				name: 'AgentProfile',
				component: () => import('../views/Broker/AgentProfile.vue'),
				meta: {
					brokerAuth: true
				}
			},
			// {
			// 	path: 'change-password',
			// 	name: 'ChangePassword',
			// 	component: () => import('../views/Broker/ChangePassword.vue'),
			// 	meta: {
			// 		brokerAuth: true
			// 	}
			// },
			{
				path: 'tariffs',
				name: 'Tariffs',
				component: () => import('../views/Broker/Tariffs.vue'),
				meta: {
					brokerAuth: true
				}
			},
			{
				path: 'broker-search',
				name: 'BrokerSearch',
				component: () => import('../views/Broker/Search.vue'),
				meta: {
					brokerAuth: true
				}
			},
			{
				path: 'broker-project/:slug',
				name: 'BrokerProject',
				component: () => import('../views/Broker/Project.vue'),
				meta: {
					brokerAuth: true
				}
			},
			{
				path: 'create-offer',
				name: 'CreateOffer',
				component: () => import('../views/Broker/EditOffer.vue'),
				meta: {
					brokerAuth: true
				}
			},
			{
				path: 'units/:pr_slug',
				name: 'Units',
				component: () => import('../views/Broker/Units.vue'),
				meta: {
					brokerAuth: true
				}
			},
			// - New pages
			{
				path: 'error/:code',
				name: 'Error',
				component: () => import('../views/Error')
			},
			{
				path: ':pathMatch(.*)*',
				name: '404',
				component: () => import('../views/Error')
			}
		]
	}
]

export default routes
