<template>
  <div class="skeleton-basic" :style='skeletonSize' />
</template>

<script>
export default {
  name: "tkSkeleton",
	props: ['width', 'height'],
	computed: {
		skeletonSize(){
			return `width: ${this.width}px; height: ${this.height}px`
		}
	}
};
</script>

<style lang="scss">
.skeleton-basic {
  background: linear-gradient(269deg, #eae9e4, #8e8e8e, #949494);
	border-radius: 5px;
  background-size: 400% 400%;
  animation: shine 1s ease infinite;
  width: 100%;
  height: 100%;
}
@keyframes shine {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
