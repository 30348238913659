import {createRequestBroker} from "@/api/requestBuilder";
import requestConfigs from "@/api/requestBrokerConfigs";

export default {
  state: function () {
    return {
      verificationCode: '',
      verificationCodeTrials: 0,
      verificationCodeAttempts: 0,
      verificationCodeMaxAttempts: 0,
      verificationCodeExpire: 0,
      verificationCodeResend: 0,
      verificationStep: 0,
      authEmail: '',
      authType: 'broker',
      lastAttemptData: {
        authEmail: '',
        authType: 'broker'
      }
    }
  },
  mutations: {
    updateVerificationCode(state, payload) {
      state.verificationCode = payload
    },
    updateVerification(state, payload) {
      state.verificationCodeAttempts = 0
      state.verificationCodeExpire = payload.expire
      state.verificationCodeResend = payload.resend
      state.verificationCodeMaxAttempts = payload.attempts
    },
    setVerificationStep(state, payload) {
      state.verificationStep = payload
      if (payload === 0) {
        state.verificationCode = ''
        state.verificationCodeAttempts = 0
        state.verificationCodeExpire = 0
        state.verificationCodeMaxAttempts = 0
        state.verificationStep = 0
      }
    },
    resetVerification(state) {
      state.verificationCode = ''
      state.verificationCodeAttempts = 0
      state.verificationCodeExpire = 0
      state.verificationCodeMaxAttempts = 0
      state.verificationStep = 0
      state.authEmail = ''
    },
    setAuthEmail(state, payload) {
      state.authEmail = payload
    },
    incrementVerificationCodeAttempts(state) {
      state.verificationCodeAttempts += 1
    },
    setAuthType(state, payload) {
      state.authType = payload
    }
  },
  actions: {
    async authRequestCode({commit, state}) {
      const payload = {
        account: state.authType,
        login: state.authEmail
      }
      if (!(state.authEmail === state.lastAttemptData.authEmail && state.authType === state.lastAttemptData.authType && Date.now() < state.verificationCodeExpire)) {
        const response = await createRequestBroker(requestConfigs.POSTAccountRequestCode, {
          jsonPayload: payload
        })
        commit('updateVerification', response.response.status)
      }

      commit('setVerificationStep', 1)
      state.lastAttemptData = {
        authType: state.authType,
        authEmail: state.authEmail
      }
    },
    async authLogin({commit, state}, code) {
      commit('incrementVerificationCodeAttempts')
      const response = await createRequestBroker(requestConfigs.POSTAccountSignIn, {
        jsonPayload: {
          account: state.authType,
          login: state.authEmail,
          code: code
        }
      })
      if (!response.status) {
        return
      }

      try {
        commit('updateEmail', state.authEmail)
        commit('updateToken', response.response.token)
        commit('updateRole', state.authType)

        const profile = await createRequestBroker(
          requestConfigs.GETProfileBroker
        )

        if (profile.status) {
          commit('updateProfile', profile.response)
        }
      } catch (error) {
        console.error('Agent Form get profile: ', error)
      }
    },
  },
  getters: {
    codeVerificationDisabled(state) {
      return state.verificationCodeAttempts >= state.verificationCodeMaxAttempts
    }
  }
}