<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		:class="{ active: active }"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 5.52779C10.9385 4.57771 9.53671 4 8 4C4.68629 4 2 6.68629 2 10C2 15.1251 12 21 12 21C12 21 22 15.1251 22 10C22 6.68629 19.3137 4 16 4C14.4633 4 13.0615 4.57771 12 5.52779Z"
			fill="#F96060"
		/>
	</svg>
</template>

<script>
export default {
	props: ['active']
}
</script>

<style lang="scss" scoped>
svg {
	path {
		fill: transparent;
		stroke: white;
	}

	&.active path {
		fill: red;
		stroke: transparent;
	}

	&.brown path {
		fill: transparent;
		stroke: $brown-main;
	}

	&.brown.active path {
		fill: $brown-main;
	}
}
</style>
