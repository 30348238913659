<template>
	<div class="info-text">
		<p>
			{{ content.title }}
		</p>
		<or-button @click="content.callback" :height="48">
			{{ content.button }}
		</or-button>
	</div>
</template>
<script>
export default {
	name: 'InfoText',
	props: {
		content: {
			type: Object,
			default: () => ({
				title: '',
				buttonText: '',
				callback: () => {}
			})
		}
	}
}
</script>
<style scoped lang="scss">
.info-text {
	padding: 60px 80px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
	max-width: 580px;
	width: 100%;
	& > p {
		font-size: 24px;
		font-weight: 700;
		line-height: normal;
		text-align: center;
		margin: 0;
	}
	& .button {
		min-width: 160px;
	}
	@media screen and (max-width: 479px) {
		padding: 20px;
		& > p {
			font-size: 16px;
		}
	}
}
</style>
