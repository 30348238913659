<template>
	<div class="error">
		<div class="error__logo">
			<img
				width="231"
				height="52"
				src="@/assets/img/svg/logo.svg"
				alt="Логотип"
			/>
		</div>
		<h2 class="error__title">{{ codeError || 404 }}</h2>
		<span class="error__subtitle">{{ $t('error.error') }}</span>
		<p class="error__text">{{ statusTextContent }}</p>
		<or-button @click="goBack" class="error__back-btn">
			{{ $t('error.toMain') }}
		</or-button>
	</div>
</template>

<script>
import { i18n } from '@/i18n'
import { setCommonMetaData, setMetaTag } from '@/utils/setMetaData'

export default {
	name: 'Error',
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.error')} - OWRealty`, {
			title: i18n.global.t('meta.errorTitle')
		})

		setMetaTag({ key: 'name', value: 'robots' }, 'noindex, follow')
	},
	data() {
		return {
			statusTextContent: ''
		}
	},
	methods: {
		async goBack() {
			await this.$router.push({
				name: 'MainRoute',
				params: { lang: this.$store.state.uiLanguage }
			})

			this.$store.commit('clearError')
		}
	},
	computed: {
		_config() {
			return {
				404: this.$t('error.404'),
				500: this.$t('error.500')
			}
		},
		codeError() {
			return this.$store.state.error.status
		}
	},
	mounted() {
		this.statusTextContent = this._config[this.codeError] ?? this._config[404]
	}
}
</script>

<style lang="scss" scoped>
.error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;

	&__title {
		margin-block-end: 0em;
	}

	&__subtitle {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: grey;
	}

	&__logo {
		img {
			margin: 0 10px 0 0;
		}
	}

	&__back-btn {
		padding: 12px 20px;
	}
}
</style>
