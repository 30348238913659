import { createRequestBroker } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'

export default {
	state: function () {
		return {
			token: null,
			email: null,
			units: [],
			profile: {},
			offer: {
				id: null,
				slug: null,
				units: [],
				properties: [
					'amenities',
					'developer',
					'external_feats',
					'internal_feats',
					'region',
					'docs'
				],
				currency: null,
				measure_units: null,
				title: '',
				url: null
			},
			role: null // broker/agency
		}
	},
	mutations: {
		updateToken(state, payload) {
			state.token = payload
		},
		updateRole(state, payload) {
			state.role = payload
		},
		updateEmail(state, payload) {
			state.email = payload
		},
		updateProfile(state, payload) {
			state.profile = payload
		},
		addUnit(state, payload) {
			if (!state.offer.units) {
				state.offer.units = [payload]

				return
			}

			if (!state.offer.units.find((itm) => itm.id === payload.id)) {
				state.offer.units.push(payload)
			}
		},
		deleteUnit(state, payload) {
			if (state.offer.units) {
				state.offer.units = state.offer.units.filter(
					(itm) => itm.id !== payload.id
				)
			}
		},
		clearUnits(state) {
			state.offer.units = []
		},
		setOffer(state, offer) {
			state.offer = offer
		},
		patchOffer(state, fields) {
			Object.assign(state.offer, fields)
		},
		logout(state) {
			Object.keys(state).forEach((key) => {
				state[key] = null
			})

			state.units = []
			state.profile = {}
		}
	},
	actions: {
		async loadOfferForEdit({ commit, state }, id) {
			const defaultOffer = {
				units: [],
				properties: [
					'amenities',
					'developer',
					'external_feats',
					'internal_feats',
					'region',
					'docs'
				],
				currency: null,
				measure_units: null,
				title: '',
				url: null,
				slug: null,
				id: null
			}

			if (!id) {
				commit('setOffer', defaultOffer)
			} else {
				try {
					const response = await createRequestBroker(
						requestBrokerConfigs.GETOffer,
						{
							routerPayload: { offer_id: id }
						}
					)

					if (!response.data) {
						return
					}

					commit('setOffer', response.data)

					response.data.units.forEach((unit) => commit('addUnit', unit))
				} catch (err) {
					console.log(err)
					commit('setError', { status: err.errCode || 404 })
				}
			}
		},
		async makeOfferCopy({ commit, state, dispatch }, id) {
			await dispatch('loadOfferForEdit', id)
			state.offer.id = null
			state.offer.slug = null
			state.offer.url = null
			state.offer.title += ' (copy)'
		}
	},
	getters: {
		isAuth: (state) => !!state.token,
		isBroker: (state) => state.role === 'broker'
	}
}
