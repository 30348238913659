<template>
	<div class="estate-modal-list" @scroll.passive="handleScroll">
		<EstateCard
			v-for="item in list"
			:key="item.id"
			:content="item"
			class="estate-modal-list__item"
			@removeFromExeptions="removeFromExeptions"
			is-modal
		/>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'

import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

import EstateCard from '@/components/cards/Estate'

export default {
	components: { EstateCard },
	data() {
		return {
			list: [],
			loading: false,
			meta: {
				per_page: 10,
				current_page: 0
			}
		}
	},
	async beforeMount() {
		await this.get()
	},
	methods: {
		handleScroll: debounce(async function (evt) {
			const scrollElement = evt.target

			if (
				scrollElement?.scrollTop + scrollElement?.clientHeight >=
				scrollElement?.scrollHeight - 1
			) {
				if (this.loading) {
					return
				}

				// Проверяем остались ли посты
				if (this.meta.current_page < (this.meta.last_page || 0)) {
					await this.get(true)
				}
			}
		}, 300),
		async get(more) {
			this.loading = true
			this.meta.current_page += 1

			createRequest(requestConfigs.GETSearch, {
				queryPayload: {
					slugs: this.slugs
				}
			})
				.then(({ response }) => {
					if (!more) {
						this.list.length = 0
					}

					this.list.push(...response.items)
					this.meta.last_page = this.lastPage
				})
				.finally(() => {
					this.loading = false
				})
		},
		removeFromExeptions({ slug }) {
			this.list = this.list.filter((itm) => itm.slug !== slug)
		}
	},
	computed: {
		slugs() {
			return this.content
				.slice(
					this.meta.per_page * (this.meta.current_page - 1),
					this.meta.per_page * this.meta.current_page || 1
				)
				.join(',')
		},
		lastPage() {
			// Делим общее количество объектов на количество объектов на странице и округляем в большую сторону
			return Math.ceil(this.content.length / this.meta.per_page)
		},
		content() {
			return this.$store.state.exeptions.estates
		}
	}
}
</script>

<style lang="scss">
.estate-modal-list {
	max-height: 600px;
	min-height: 400px;
	overflow-y: auto;

	&__item {
		margin: 0 auto;
		margin-bottom: 40px;
	}
}
</style>
