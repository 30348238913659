<template>
	<div class="common-modal success-request-modal">
		<div class="common-modal__container">
			<span class="common-modal__title">
				{{ $t('modal.titleFinishApplication') }}
			</span>
			<span class="common-modal__text">
				{{ $t('modal.titleFinishText1') }}
			</span>
			<or-button
				type="submit"
				class="common-modal__submit success-request-modal__btn"
				aria-label="Button close"
				@click="$store.commit('hideModal')"
			>
				{{ $t('general.good') }}
			</or-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SuccessRequestModal'
}
</script>

<style lang="scss">
.success-request-modal {
  &__btn {
    max-width: 160px;
    margin-top: 30px;
  }
}
</style>