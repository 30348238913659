<template>
	<div
		class="main-container"
		id="main-container"
		:class="{ 'main-container--map': $route.name === 'Places' }"
	>
		<template v-if="!isError && !isOfferPage">
			<HeaderBroker v-if="isBroker" />
			<HeaderMain v-else />
		</template>
		<div v-if="!isError">
			<suspense>
				<router-view />
			</suspense>
		</div>
		<Error v-else />
		<Tray />
		<Footer
			v-if="!isError && !isOfferPage"
			:is-broker="isBroker"
			class="main-container__footer main-footer"
		/>
		<BrokerMenu v-if="$store.getters.isBroker" />
	</div>
	<Modal />
	<Notification />
	<tg-link v-if="isBroker || isLoginPage" />
</template>

<script>
import { addTranslationLines } from '@/i18n'
import { defineAsyncComponent } from 'vue'
import { setMetaTag } from '@/utils/setMetaData'
import languagesCode from '@/enums/languagesCode'
import Notification from '@/components/Notification'
import Footer from '@/components/Footer'
import Modal from '@/components/Modal'
import Tray from '@/components/Tray'
import Error from '@/views/Error'
import BrokerMenu from '@/modules/broker/components/BrokerMenu.vue'
import TgLink from '@/components/LayoutSection/TgLink.vue'

const HeaderBroker = defineAsyncComponent(() =>
	import('@/components/Header/broker.vue')
)
const HeaderMain = defineAsyncComponent(() =>
	import('@/components/Header/main.vue')
)

export default {
	components: {
		TgLink,
		BrokerMenu,
		HeaderMain,
		HeaderBroker,
		Footer,
		Modal,
		Tray,
		Error,
		Notification
	},
	watch: {
		'$store.state.uiLanguage': function () {
			this.$i18n.locale = this.$store.state.uiLanguage
			addTranslationLines(this.$store.state.uiLanguage)
		}
	},
	computed: {
		isOfferPage() {
			return ['BrokerOffer'].includes(this.$route.name)
		},
		isLoginPage() {
			return ['Login'].includes(this.$route.name)
		},
		isBroker() {
			return [
				'BrokerOffer',
				'BrokerProfile',
				'ChangePassword',
				'BrokerSearch',
				'BrokerProject',
				'Units',
				'Tariffs',
				'CreateOffer'
			].includes(this.$route.name)
		},
		isError() {
			return this.$store.state.error.status
		}
	},
	async beforeCreate() {
		setMetaTag(
			{ key: 'property', value: 'og:locale' },
			languagesCode[this.$store.state.uiLanguage] || 'en_US'
		)

		await this.$store.dispatch('init')
	}
}
</script>

<style lang="scss">
@import '~@/styles/style.scss';

.main-container {
	height: 100vh;
	transition: height 0.36s ease-out;
	display: flex;
	flex-direction: column;
	&--map {
		.main-footer {
			padding-top: 0;
			min-height: 440px;
		}
	}

	&__footer {
		margin-top: auto;
		margin-bottom: 0;
	}
}
</style>
