<template>
	<!-- Добавляем active если чекбокс активен 	-->
	<div
    class="company-agent"
    :class="{ active: checked }"
    :role="!!subscription ? 'input' : ''"
    @click="update"
  >
		<div v-if="agent" class="company-agent__left">
			<p class="company-agent__name">{{ agent?.name }}</p>
			<div class="company-agent__info-item">
				<p>{{ $t('web.email') }}</p>
				<a :href="`mailto:${agent?.email}`" target="_blank">{{ agent?.email }}</a>
			</div>
			<div class="company-agent__bottom" v-if="subscription">
				<div>
					{{ $t('web.valid_until') }}:
					<span>{{ getDateInFormat(subscription.expires_at, $store.state.uiLanguage) }}</span>
				</div>
			</div>
		</div>
    <div v-else class="company-free-slot__left">
      <span>{{ $t('web.free_slot_until') }}</span>
      <p>{{ getDateInFormat(subscription.expires_at, $store.state.uiLanguage) }}</p>
    </div>
		<div class="company-agent__right" v-if="subscription">
			<or-checkbox :checked="checked" @click.prevent />
		</div>
	</div>
</template>

<script setup>
import {getDateInFormat} from "@/utils/dateTime";
import {ref} from "vue";

const props = defineProps({
  agent: Object,
  subscription: Object,
  checked: Boolean
})

const emit = defineEmits(['update'])

function update() {
  emit('update', props.subscription.id)
}
</script>

<style lang="scss">
.company-agent {
	border-radius: 4px;
	border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
	background: var(--opacity-black-2, rgba(0, 0, 0, 0.02));
	padding: 20px;
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	transition: all 0.2s ease;

	&.active {
		border: 1px solid var(--01, #c99d56);
	}
	&__left {
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 100%;
	}
	&__name {
		padding: 0;
		margin: 0;
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
	}
	&__info-item {
		& p:first-child {
			margin: 0;
			color: var(--secondary-1, #808080);
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
		}
		& p:last-child {
			font-size: 14px;
			font-weight: 600;
			line-height: normal;
			word-break: break-all;
		}

		a {
			font-weight: 600;
			color: var(--01, #c99d56);
			font-size: 14px;
		}
	}
	&__bottom {
		display: flex;
		flex-direction: column;
		gap: 5px;
		& > div {
			font-size: 12px;
			color: var(--Secondary-1, #808080);
			span {
				color: var(--Text-1, #000);
			}
		}
	}
	@media screen and (max-width: 479px) {
		padding: 10px;
		&__left {
			gap: 10px;
		}
		&__name {
			font-size: 14px;
		}
		&__info {
			grid-template-columns: 1fr;
			gap: 5px;
		}
		&__info-item {
			& p:first-child {
				font-size: 12px;
				font-weight: 400;
			}
			& p:last-child {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}
</style>
