<template>
	<div class="company-modal">
		<div class="company-modal__body">
			<company-agent :agent="content.agent" />
			<div class="company-modal__move-to">
				<span></span>
				{{ $t('web.move_to') }}
				<span></span>
			</div>
			<template v-if="content.subscriptions.length === 0">
				<company-no-slot />
				<or-button
					class="company-modal__btn"
					@click="navigateToChangePlan"
					:height="48"
				>
					{{ $t('web.change_plan') }}
				</or-button>
			</template>
			<template v-else>
				<company-free-slot
					v-for="subscription in content.subscriptions"
					:key="subscription.id"
					:subscription="subscription"
					@click="() => assignAgent(subscription.id)"
				/>
			</template>
		</div>
		<!--		<or-button class="w-180 company-modal__btn"> Button </or-button>-->
	</div>
</template>

<script>
import CompanyAgent from '@/components/modals/CompanyOffice/components/CompanyAgent.vue'
import CompanyFreeSlot from '@/components/modals/CompanyOffice/components/CompanyFreeSlot.vue'
import CompanyNoSlot from '@/components/modals/CompanyOffice/components/CompanyNoSlot.vue'
import OrButton from '@/components/global/orButton.vue'

export default {
	name: 'AssignAgentModal',
	components: { OrButton, CompanyNoSlot, CompanyFreeSlot, CompanyAgent },
	props: {
		content: Object
	},
	methods: {
		assignAgent(id) {
			this.content.callback(id, this.content.agent.slug)
			this.$store.commit('hideModal')
		},
		navigateToChangePlan() {
			this.$router.push({
				name: 'Tariffs',
				params: { lang: this.$store.state.uiLanguage }
			})
			this.$store.commit('hideModal')
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/components/modal/company-modal';
</style>
