<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.59 7.24084C12.0244 8.25746 11.2038 9.08127 10.2346 9.65002L9.05081 8.46675C9.34788 8.05325 9.52192 7.5466 9.52192 7.00006C9.52192 5.6093 8.39068 4.47759 6.99992 4.47759C6.45231 4.47759 5.94672 4.65254 5.53263 4.949L4.54544 3.96134C5.31057 3.64254 6.14324 3.47061 6.99992 3.47061C9.31788 3.47061 11.4603 4.73072 12.59 6.7594C12.6785 6.91679 12.6785 7.08332 12.59 7.24084ZM13.4827 7.02912V6.97107C13.4779 6.76151 13.4214 6.55249 13.3132 6.35718C12.0364 4.06667 9.61792 2.64395 6.99992 2.64395C5.9167 2.64395 4.86801 2.88761 3.91981 3.33515L3.25667 2.67247C3.09463 2.51103 2.83358 2.51103 2.67154 2.67247C2.51101 2.83391 2.51101 3.09556 2.67154 3.25697L3.16365 3.74877C2.14944 4.3849 1.28978 5.27398 0.68666 6.35718C0.458613 6.76586 0.458613 7.23439 0.68666 7.64277C1.96192 9.93327 4.38039 11.3563 6.99992 11.3563C8.08315 11.3563 9.13183 11.1125 10.08 10.6648L10.7432 11.3279C10.8227 11.4085 10.9292 11.4487 11.0342 11.4487C11.1408 11.4487 11.2458 11.4085 11.3268 11.3279C11.4888 11.1664 11.4888 10.9049 11.3268 10.7433L10.8362 10.2515C11.8504 9.61521 12.71 8.72597 13.3132 7.64274C13.4214 7.44759 13.4779 7.23868 13.4827 7.02912ZM7.87008 8.45491L5.54463 6.12976C5.39159 6.38436 5.30308 6.68216 5.30308 7.00008C5.30308 7.93521 6.06373 8.69586 6.99992 8.69586C7.31796 8.69583 7.61502 8.60779 7.87008 8.45491ZM8.45368 7.87038C8.60672 7.61595 8.69524 7.31812 8.69524 7.00006C8.69524 6.06493 7.93459 5.30428 6.9999 5.30428C6.68183 5.30428 6.38327 5.39219 6.12973 5.54523L8.45368 7.87038ZM6.9999 10.5298C7.85507 10.5298 8.68774 10.3577 9.45438 10.0388L8.46568 9.05127C8.05309 9.3479 7.546 9.52238 6.9999 9.52238C5.60911 9.52238 4.47639 8.39114 4.47639 7.00008C4.47639 6.45367 4.65191 5.94718 4.94898 5.53356L3.76524 4.35012C2.79454 4.9189 1.97537 5.74254 1.40826 6.75946C1.32125 6.91685 1.32125 7.08337 1.40826 7.2409C2.53802 9.26956 4.68043 10.5298 6.9999 10.5298Z"
			fill="#F96060"
		/>
	</svg>
</template>
