<template>
	<div class="map-modal">
		<MapBox style="height: 580px" :coords="coords" :center="center" />
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		MapBox: defineAsyncComponent(() => import('@/components/MapBox.vue'))
	},
	props: {
		content: {
			type: Object,
			default: () => ({
				slug: null,
				lat: null,
				lng: null
			})
		}
	},
	computed: {
		coords() {
			return [{ ...this.content }]
		},
    center() {
      return {
        lat: this.content.lat,
        lng: this.content.lng
      }
    }
	}
}
</script>

<style lang="scss">
.map-modal {
	width: 100%;
	max-width: 900px;
	min-height: 650px;
	background-color: white;
	border-radius: 4px;

	.mapboxgl-ctrl-bottom-right {
		display: none;
	}
}
</style>
