import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import { getScrollWidth } from '@/utils/getScrollWidth'
import broker from './modules/broker'
import auth from './modules/auth'

export default createStore({
	state: {
		currentModal: {
			name: '',
			props: null,
			isShow: false
		},
		language: 'en',
		uiLanguage: '',
		isLangFileLoaded: true,
		adsType: [],
		favoriteList: [],
		trayItems: [],
		stagesBuilding: [],
		statusesAd: [],
		activeMoney: 'USD',
		notification: {
			isShow: false,
			text: '',
			type: '' // error/success/info. Default info
		},
		error: {
			status: null
		},
		exeptions: {
			projects: [],
			developers: [],
			estates: []
		},
		areaUnit: 'square_meter', // square_foot
		scrollWidth: 0
	},
	mutations: {
		async showModal(state, payload) {
			state.currentModal.name = payload.name
			state.currentModal.props = payload.props
			state.currentModal.isShow = true
			state.currentModal.time = new Date();
			await getScrollWidth()
			if (typeof document !== 'undefined') {
				document.body.style.overflow = 'hidden'
				document.body.style.paddingRight = `${state.scrollWidth}px`
			}
		},
		hideModal({ currentModal }) {
			currentModal.isShow = false
			if (typeof document !== 'undefined') {
				document.body.style.overflow = 'auto'
				document.body.style.paddingRight = `unset`
			}
		},
		showNotif(state, { text, type }) {
			state.notification = { text, type, isShow: true }
		},
		hideNotif(state) {
			state.notification = { text: '', type: '', isShow: false }
		},
		setData(state, { key, value }) {
			state[key] = value
		},
		setError(state, { status }) {
			state.error.status = status
		},
		clearError(state) {
			state.error.status = null
		},
		setIsLangFileLoaded(state, data) {
			state.isLangFileLoaded = data
		},
		setUiLanguage(state, data) {
			state.uiLanguage = data
		},
		setAreaUnit(state, data) {
			state.areaUnit = data
		},
		editFavoriteList(state, data) {
			data?.edit
				? state.favoriteList.push(data.slug)
				: (state.favoriteList = state.favoriteList.filter(
						(item) => data.slug !== item
				  ))
		},
		pushToTray(state, data) {
			state.trayItems.unshift({
				text: data.text,
				type: ['success', 'error'].filter((type) => type === data.type),
				id: new Date()
			})
			if (state.trayItems.length > 3) state.trayItems.pop()
		},
		removeFromTray(state, itemId) {
			state.trayItems = state.trayItems.filter((item) => item.id !== itemId)
		},
		setActiveMoney(state, money) {
			state.activeMoney = money
		},
		addToExeptions(state, { type, slug }) {
			if (!state.exeptions[type].find((itm) => itm === slug)) {
				state.exeptions[type].push(slug)
			} else {
				throw new Error('Alredy added')
			}
		},
		removeFromExeptions(state, { type, slug }) {
			state.exeptions[type] = state.exeptions[type].filter(
				(itm) => itm !== slug
			)
		}
	},
	actions: {
		async init({ commit }) {
			// Загружаем глобальные статичные списки, может стоит их лишний раз не перезапрашивать

			// Типы объявлений
			const adsType = await createRequest(requestConfigs.GETADSType)

			if (adsType?.status) {
				commit('setData', { key: 'adsType', value: adsType.response })
			}

			// Этаппы строительства
			const stagesBuilding = await createRequest(
				requestConfigs.GETStagesBuilding
			)

			if (stagesBuilding?.status) {
				commit('setData', {
					key: 'stagesBuilding',
					value: stagesBuilding.response
				})
			}

			// Статусы объявлений
			const statusesAd = await createRequest(requestConfigs.GETAdStatuses)

			if (statusesAd?.status) {
				commit('setData', { key: 'statusesAd', value: statusesAd.response })
			}
		}
	},
	modules: {
		broker,
		auth
	},
	plugins: [
		createPersistedState({
			paths: [
				'adsType',
				'areaUnit',
				'exeptions',
				'uiLanguage',
				'activeMoney',
				'favoriteList',
				'broker.token',
				'broker.role',
				'broker.email',
				'broker.units',
				'broker.profile'
			]
		})
	]
})
