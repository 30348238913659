<template>
	<div class="expires-modal">
		<div class="expires-modal__title">
			{{ $t('web.subscription_expires_text') }} 5 {{ $t('web.days') }}
		</div>
		<or-button class="w-160"> {{ $t('web.extend') }} </or-button>
	</div>
</template>
<script>
import OrButton from '@/components/global/orButton.vue'

export default {
	name: 'ExpiresModal',
	components: { OrButton }
}
</script>
<style lang="scss">
.expires-modal {
	border-radius: 4px;
	background: #fff;
	max-width: 580px;
	width: 100%;
	padding: 40px 80px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	&__title {
		color: #000;
		text-align: center;
		font-size: 24px;
		font-weight: 700;
		line-height: normal;
	}
	@media screen and (max-width: 620px) {
		padding: 20px;
		&__title {
			font-size: 16px;
		}
	}
}
</style>
