export default {
  en: 'en_US',
  ru: 'ru_RU',
  tr: 'tr_TR',
  ar: 'ar_AE',
  ur: 'ur_PK',
  pa: 'pa_PK',
  de: 'de_DE',
  zh: 'zh_CN',
  pt: 'pt_PT'
}