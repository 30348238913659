export const areaConfig = [
	{ title: 'm²', value: 'square_meter' },
	{ title: 'ft²', value: 'square_foot' }
]

export const squareTitles = {
	square_meter: 'm2',
	square_foot: 'ft2'
}

export const squareSymbols = {
	square_meter: `m²`, //`&#13217;`
	square_foot: `ft²`
}

export const squarePayloadText = {
  square_meter: 'meter',
  square_foot: 'feet',
}