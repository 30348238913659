export const simbol = {
	AED: 'AED ',
	RUB: 'RUB ',
	USD: 'USD ',
	AUD: 'AUD ',
	CAD: 'CAD ',
	EUR: 'EUR ',
	GBP: 'GBP ',
	CNY: 'CNY '
}

export const money = {
	AED: 'aed',
	RUB: 'rub',
	USD: 'dollar',
	AUD: 'aud',
	CAD: 'cad',
	EUR: 'eur',
	GBP: 'gbp',
	CNY: 'cny'
}

export const moneyReverseMap = {
	aed: 'AED',
	rub: 'RUB',
	dollar: 'USD',
	aud: 'AUD',
	cad: 'CAD',
	eur: 'EUR',
	gbp: 'GBP',
	cny: 'CNY'
}
