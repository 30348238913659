<template>
	<div class="tg-link" :class="className">
		<a
			class="tg-link__body"
			target="_blank"
			href="https://t.me/owrealtysupport"
		>
			<div class="tg-link__icon">
				<img
					alt="Логотип мессенджера Telegram"
					decoding="async"
					src="@/assets/img/svg/telegram.svg"
				/>
			</div>
			<div class="tg-link__btn">Support</div>
		</a>
	</div>
</template>
<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const route = useRoute()
const store = useStore()
const className = computed(() => {
	return {
		search: route.name === 'BrokerSearch',
		login: route.name === 'Login',
		agency: !store.getters.isBroker
	}
})
</script>
<style lang="scss">
.tg-link {
	position: fixed;
	z-index: 12;
	bottom: 70px;
	right: 40px;
	transition: all 0.3s ease-in-out;
	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
	}
	&__icon {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background: #259fdc;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

		img {
			width: 40px;
			height: 40px;
		}
	}
	&__btn {
		border-radius: 100px;
		display: flex;
		padding: 3px 8px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		background: rgba(37, 159, 220, 0.8);
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		line-height: 130%;
		color: white;
	}
	@media screen and (max-width: 768px) {
		right: 20px;
		&__icon {
			width: 50px;
			height: 50px;

			img {
				width: 30px;
				height: 30px;
			}
		}
		&__btn {
			display: none;
		}
	}
	&.search {
		@media screen and (max-width: 768px) {
			bottom: 120px;
		}
	}
	&.login {
		bottom: 40px;
	}
	&.agency {
		@media screen and (max-width: 768px) {
			bottom: 40px;
		}
	}
}
</style>
