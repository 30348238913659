<template>
	<div class="thumb-example">
		<or-carousel
			id="gallery"
			:items-to-show="1"
			v-model="currentSlide"
			class="gallery-top"
			ref="mainCarousel"
			@mousedown="slideStart('gallery')"
			@mouseup="slideEnd('gallery')"
		>
			<or-slide v-for="(item, index) in content.items" :key="index">
				<or-image
					:src="item"
					class="gallery-top__image"
					@mouseout="focusOut('gallery')"
				/>
			</or-slide>
			<template #addons>
				<or-carousel-navigation />
			</template>
		</or-carousel>

		<or-carousel
			id="thumbnails"
			:items-to-show="2"
			:value="currentSlide"
			ref="carousel"
			class="gallery-preview"
			:breakpoints="breakpoints"
			:mouseDrag="touchDrag"
			:touchDrag="touchDrag"
			snapAlign="start"
			@mousedown="slideStart('thumbnails')"
			@mouseup="slideEnd('thumbnails')"
		>
			<or-slide
				v-for="(item, index) in content.items"
				:key="item"
				@click="slideTo(index)"
				:class="[
					{ 'carousel__slide--active': index === currentSlide },
					{ 'carousel__slide--none-active': index !== currentSlide }
				]"
			>
				<or-image
					:src="item"
					class="gallery-top__image"
					@mouseout="focusOut('thumbnails')"
				/>
			</or-slide>
		</or-carousel>
	</div>
</template>

<script>
export default {
	name: 'PlaceCarousel',
	props: {
		content: {
			type: Object,
			default: () => ({
				items: [],
				index: 0
			})
		}
	},
	data() {
		return {
			correctNumberImg: 1,
			currentSlide: 0,
			width: window.innerWidth,
			breakpoints: {
				767: {
					itemsToShow: 5
				},
				480: {
					itemsToShow: 3
				},
				319: {
					itemsToShow: 2
				}
			}
		}
	},
	computed: {
		touchDrag() {
			let touchDragValue = true
			if (this.width > 767 && this.content.items.length <= 5) {
				touchDragValue = false
			} else if (
				this.width > 480 &&
				this.width < 768 &&
				this.content.items.length <= 3
			) {
				touchDragValue = false
			} else
				touchDragValue = !(
					this.width > 319 &&
					this.width < 481 &&
					this.content.items.length <= 2
				)
			return touchDragValue
		}
	},
	methods: {
		updateWidth() {
			this.width = window.innerWidth
		},
		slideTo(val) {
			this.currentSlide = val
		},
		hide() {
			this.$store.commit('hideModal')
		},
		owlKyeboardNavigation(event) {
			if (event.keyCode === 27) {
				this.hide()
				return
			}
			if (event.keyCode === 37) {
				if (this.correctNumberImg > 1) this.correctNumberImg -= 1
				this.$refs.mainCarousel.prev()
			}
			if (event.keyCode === 39) {
				if (this.correctNumberImg < this.content.length)
					this.correctNumberImg += 1
				this.$refs.mainCarousel.next()
			}
		},
		findElement(id, className) {
			return document.getElementById(id).getElementsByClassName(className)
		},
		addClass(element, styleValue) {
			if (element && element.length > 0) {
				for (let item of element) {
					item.style.cursor = styleValue
				}
			}
		},
		slideStart(id) {
			const el = this.findElement(id, 'image-component')
			const skeleton = this.findElement(id, 'skeleton-basic')
			this.addClass(el, 'grabbing')
			this.addClass(skeleton, 'grabbing')
		},
		slideEnd(id) {
			const el = this.findElement(id, 'image-component')
			const skeleton = this.findElement(id, 'skeleton-basic')
			this.addClass(el, 'grab')
			this.addClass(skeleton, 'grab')
		},
		focusOut(id) {
			const el = this.findElement(id, 'image-component')
			const skeleton = this.findElement(id, 'skeleton-basic')
			this.addClass(el, 'grab')
			this.addClass(skeleton, 'grab')
		}
	},
	mounted() {
		window.addEventListener('keydown', this.owlKyeboardNavigation, {
			passive: true
		})
		window.addEventListener('resize', this.updateWidth, {
			passive: true
		})
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.owlKyeboardNavigation)
		window.removeEventListener('resize', this.updateWidth)
	},
	watch: {
		currentSlide() {
			this.$refs.carousel.slideTo(this.currentSlide)
		}
	}
}
</script>

<style lang="scss">
.thumb-example {
	position: relative;
	//max-width: 700px;
	margin: 0 auto;
	border-radius: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	grid-gap: 20px;
	height: 100%;

	&__close {
		position: absolute;
		top: 10px;
		right: 0;
		z-index: 10;
		width: 30px;
		height: 30px;
		background-color: transparent;
		border: 0;
		box-shadow: none;
		background-image: url('~@/assets/img/svg/buttons/close-place-carousel.svg');
		background-size: 15px 15px;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	.image-component,
	.skeleton-basic {
		cursor: grab;
	}
}

.gallery-top {
	flex-grow: 1;
	display: flex;
	align-items: center;
	&.carousel {
		picture {
			height: 90%;
			width: 90%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		&__track {
			max-width: 400px;
		}
		.skeleton-basic {
			width: 60%;
			height: 90%;
		}
	}

	.carousel {
		&__viewport {
			height: 100%;
		}
		&__track {
			height: 100%;
		}
		&__slide {
			width: 100%;
			max-height: 700px;
			height: 100%;
		}
	}
}

.gallery-preview {
	&.carousel {
		picture {
			width: 100%;
			height: 140px;
			position: relative;
			border-radius: 5px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel {
		&__slide {
			transition: all 0.4s ease-in-out;
			padding: 0 10px;
			&--active {
				opacity: 1;
				transform: scale(1.05);
				&.carousel__slide--none-active {
					transform: scale(1);
					picture {
						&::after {
							transition: all 0.3s ease-in-out;
							content: '';
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							background: rgba(0, 0, 0, 0.7);
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
			&--none-active {
				picture {
					&::after {
						transition: all 0.3s ease-in-out;
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						background: rgba(0, 0, 0, 0.7);
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}
				}
			}
			&:hover {
				opacity: 1;
				transform: scale(1.05);
				picture {
					&::after {
						opacity: 0;
					}
				}
			}
		}
	}
}

.carousel {
	&__next,
	&__prev {
		&:hover {
			.carousel__icon {
				transform: scale(2.4);
				fill: white;
			}
		}
		&--disabled {
			display: none;
		}
		top: calc(50% + 16px);
	}
	&__icon {
		transition: all 0.3s ease-in-out;
		transform: scale(1.8);
		fill: gray;
	}
}
@media screen and (max-width: 767px) {
	.gallery-top {
		&.carousel {
			picture {
				width: 100%;
			}
			.skeleton-basic {
				width: 100%;
				height: 50%;
			}
		}
	}
	.carousel {
		&__icon {
			fill: black;
			background: white;
			width: 18px;
			height: 18px;
			border-radius: 50%;
		}
		&__next,
		&__prev {
			&:hover {
				.carousel__icon {
					fill: black;
				}
			}
		}
	}
}
@media screen and (min-device-width: 1024px) and (orientation: landscape) {
	.gallery-preview {
		//display: none;
	}
	.gallery-top {
		height: 100%;
		.carousel {
			&__track {
				align-items: center;
			}
			&__slide {
				max-height: 500px;
			}
		}
	}
}
@media screen and (max-device-width: 1023px) and (orientation: landscape) {
	.thumb-example {
		display: block;
	}
	.gallery-preview {
		display: none;
	}
	.gallery-top {
		height: 100%;
		.carousel {
			&__track {
				align-items: center;
			}
		}
	}
}
@media screen and (max-device-width: 1023px) and (orientation: portrait) {
	.gallery-top {
		height: 100%;
		.carousel {
			&__track {
				align-items: center;
			}
		}
	}
}
@media screen and (max-width: 479px) {
	.gallery-preview {
		&.carousel {
			picture {
				height: 90px;
			}
		}
	}
	.gallery-top {
		.carousel__slide {
			max-height: unset;
		}
	}
}
</style>
