<template>
  <i class="icon" :class="['icon-' + type]">
    <slot/>
  </i>
</template>

<script>
export default {
  name: 'GlobalIcon',
  props: {
    type: {
      type: String,
    },
  }
}
</script>