<template>
	<div class="company-no-slot">
		{{ $t('web.no_free_subscription') }}
	</div>
</template>

<script>
export default {
	name: 'CompanyNoSlot'
}
</script>

<style lang="scss">
.company-no-slot {
	padding: 32px;
	border-radius: 4px;
	border: 1px dashed var(--opacity-black-30, rgba(0, 0, 0, 0.3));
	background: var(--Background-1, #fff);
	text-align: center;
	color: var(--Primary-1, #c99d56);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	display: flex;
	align-items: center;
	margin: 0;
	min-height: 107px;
	@media screen and (max-width: 620px) {
		padding: 22px;
		min-height: unset;
	}
}
</style>
