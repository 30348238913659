<template>
	<div class="estate-card">
		<div class="estate-card__header">
			<div>
				<span class="estate-card__stage">
					{{ content.build_status?.title }}
				</span>
				<h3 class="estate-card__title">{{ content?.project.title }}</h3>
			</div>
			<div>
				<template v-if="!isModal">
					<button
						type="button"
						@click.stop.prevent="clickLike"
            @touchstart.stop.prevent="clickLike"
						class="btn estate-card__favorite"
						aria-label="Favorite"
					>
						<LikeSvg :active="isFavorite" />
					</button>
					<div class="estate-card__exeption-block add-exeptions">
						<or-drop-down>
							<template #toggle>
								<button
									type="button"
									class="btn add-exeptions__btn"
									aria-label="Exception"
								>
									<or-icon type="exeption" />
								</button>
							</template>
							<template #content>
								<div class="add-exeptions__content">
									<ul>
										<li v-for="(itm, idx) in config.exeptions_list" :key="idx">
											<button
												type="button"
												class="btn"
												@click="addToExeption(itm.value)"
											>
												{{ $t(itm.title) }}
											</button>
										</li>
									</ul>
								</div>
							</template>
						</or-drop-down>
					</div>
				</template>
				<button
					v-else
					type="button"
					@click.stop="removeFromExeption"
					class="btn estate-card__remove-exeption"
				>
					<or-icon type="close" />
				</button>
			</div>
		</div>
		<div class="estate-card__carousel-container">
			<div
				class="estate-card__img-wrapper"
				:class="{ 'estate--no--photo': content.images == null }"
			>
				<or-image
					:src="images[0]"
					:width="280"
					:alt="content.title"
				/>
			</div>
		</div>
		<div class="estate-card__content">
			<p v-if="content.flags?.length" class="estate-card__flags">
				<template v-for="flag in content.flags">
					<span v-if="flag" :key="flag">{{ flag }}</span>
				</template>
			</p>

			<div class="estate-card__full-line">
				<span class="estate-card__price">{{ price }}</span>
				<span class="estate-card__housing-type">{{ content.type?.title }}</span>
			</div>
			<div class="estate-card__description">
				<div>
					<template v-if="content.complex?.title"
						>{{ content.complex?.title }},
					</template>
					{{ content.project?.title }}, {{ content.region?.title }},
					{{ content.city?.title }}
				</div>
				<div>
					<p>{{ content.developer?.title }}</p>
					<span class="estate-card__description--type">
						{{ $t('general.developer') }}
					</span>
				</div>
			</div>

			<ul class="estate-card__properties">
				<li>
					<div class="estate-property">
						<or-icon type="square" />
						<span class="estate-property__value">
							{{ square.value }}
							<sup>{{ square.title }}</sup>
						</span>
						<span class="estate-property__title estate-property__title--square">
						</span>
					</div>
					<div class="estate-property">
						<or-icon type="bed" />
						<span v-if="content.is_studio" class="estate-property__studio">{{
							$t('general.studio')
						}}</span>
						<template v-if="content.beds_count">
							<span class="estate-property__value">{{
								content.beds_count
							}}</span>
							<span class="estate-property__title">{{
								$t('general.rooms')
							}}</span>
						</template>
					</div>
				</li>
				<li>
					<div class="estate-property">
						<or-icon type="bathtub" />
						<span class="estate-property__value">
							{{ content.closet_count }}
						</span>
						<span class="estate-property__title">{{
							$t('general.baths')
						}}</span>
					</div>
					<div v-if="content.floor" class="estate-property">
						<or-icon type="floor" />
						<span class="estate-property__value">{{ content.floor }}</span>
						<span class="estate-property__title">{{
							$t('general.floor')
						}}</span>
					</div>
				</li>
			</ul>

			<p class="estate-card__date">{{ date }}</p>
		</div>
	</div>
</template>

<script>
import { money, simbol } from '@/enums/moneyСollections.js'
import { squareTitles } from '@/enums/areaCollections.js'
import { formatNumber } from '@/utils/formatMoney'
import { getDateInFormat } from '@/utils/dateTime'
import eventBus from '@/plugins/eventBus'

import LikeSvg from '@/components/svg/Like'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		isModal: {
			// если тру, то используется в модальном окне исключений и нужно добавить удаление из исключений
			type: Boolean,
			default: false
		}
	},
	components: { LikeSvg },
	computed: {
		images() {
			if (this.content?.logo) {
				let list = []
				list.push(this.content?.logo)
				return list
			} else if (this.content?.images === null) {
				let list = []
				list.push(require(`@/assets/img/svg/logo.svg`))
				return list
			} else return this.content?.images
		},
		price() {
			if (this.content?.price) {
				return `${simbol[this.$store.state.activeMoney]}${formatNumber(
					this.content.price[money[this.$store.state.activeMoney]]
				)}`
        // return new Intl.NumberFormat('ja-JP', {
        //   style: 'currency',
        //   currency: this.$store.state.activeMoney,
        //   maximumFractionDigits: 0
        // }).format(
        //   this.content.price[money[this.$store.state.activeMoney]]
        // );
			} else return ''
		},
		square() {
			return {
				value: this.content.area[this.$store.state.areaUnit],
				title: squareTitles[this.$store.state.areaUnit]
			}
		},
		date() {
			return getDateInFormat(
				this.content.created_at,
				this.$store.state.language
			)
		},
		isFavorite() {
			return this.$store.state.favoriteList.find((el) => {
				return el === this.content.slug
			})
		},
		config() {
			return {
				carousel: {
					itemsToShow: 1,
					margin: 10,
					mouseDrag: this.images?.length > 1,
					touchDrag: this.images?.length > 1
				},
				exeptions_list: [
					{
						title: 'general.notShowAd',
						value: 'estates'
					},
					{
						title: 'general.notShowDeveloper',
						value: 'developers'
					},
					{
						title: 'general.notShowProject',
						value: 'projects'
					}
				]
			}
		}
	},
	methods: {
		removeFromExeption() {
			this.$store.commit('removeFromExeptions', {
				type: 'estates',
				slug: this.content.slug
			})

			this.$emit('removeFromExeptions', { slug: this.content.slug })

			this.$store.commit('showNotif', {
				text: this.$t('notification.successRemoveFromExeption'),
				type: 'success'
			})
		},
		addToExeption(type) {
			const { commit } = this.$store

			try {
				const fields = {
					projects: 'project',
					developers: 'developer',
					estates: ''
				}
				const value = fields[type]
					? this.content[fields[type]] || this.content
					: this.content

				commit('addToExeptions', { type, slug: value?.slug })

				commit('showNotif', {
					text: this.$t('notification.successAddToExeptions'),
					type: 'success'
				})

				eventBus.trigger('dropdown-hide')
			} catch (e) {
				commit('showNotif', {
					text: this.$t('notification.alreadyAddedToExeptions'),
					type: 'error'
				})
			}
		},
		clickLike() {
			this.$store.commit('editFavoriteList', {
				edit: this.isFavorite ? false : true,
				slug: this.content.slug
			})
		}
	}
}
</script>

<style lang="scss">
.estate--no--photo {
	img {
		object-fit: none !important;
	}
}
</style>

<style lang="scss">
.estate-card {
	position: relative;
	max-width: 280px;
	cursor: pointer;
	overflow: hidden;

	@media (max-width: 500px) {
		max-width: 100%;
	}

	&__header {
		position: absolute;
		top: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		padding: 15px 13px;
		z-index: 1;

		> div {
			&:first-child {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
			}
			&:last-child {
				display: flex;
				flex-direction: row;
				align-items: center;
				grid-gap: 5px;
			}
		}
	}

	&__stage {
		display: inline-block;
		padding: 5px 10px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 4px;
	}

	&__title {
		max-width: 200px;
		position: relative;
		margin: 0;
		flex-basis: 100%;
		margin-top: 9px;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: #ffffff;
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
		z-index: -1;
	}

	&__favorite {
		position: unset;
	}

	&__remove-exeption {
		position: unset;
		color: white;
	}

	.add-exeptions {
		position: unset;
		z-index: 5;

		&__btn {
			font-size: 20px;
			color: white;
		}

		&__content {
			position: relative;
			min-width: 190px;
			margin-left: -180px;
			background: #ffffff;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			z-index: 5;

			li {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				&:last-child {
					border-bottom: none;
				}
			}

			button {
				width: 100%;
				padding: 10px;
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				text-align: left;
				color: #808080;

				&:hover {
					color: #000000;
					background: #f7f7f7;
				}
			}
		}
	}

	&__carousel-container {
		margin-bottom: 15px;
		border-radius: 5px;
		overflow: hidden;
	}

	&__img-wrapper {
		position: relative;
		width: 100%;

		img {
			object-fit: cover;
			object-position: center;
			aspect-ratio: 16 / 10;
			height: 100%;
		}
		.skeleton-basic {
			width: 280px;
			height: 100%;
			aspect-ratio: 16 / 10;
		}
		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		}
		@media screen and (max-width: 500px) {
			img {
				max-width: unset;
				width: 100%;
			}
		}
	}

	&__flags {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-top: 0;
		margin-bottom: 5px;

		span {
			display: inline-block;
			padding: 5px 10px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #ffffff;
			background: #c99d56;
			border: 1px solid #c99d56;
			border-radius: 4px;
		}
	}

	&__full-line {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 5px;
	}

	&__price {
		display: inline-block;
		margin-right: 10px;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
	}

	&__housing-type {
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		color: #c99d56;
	}

	&__description {
		margin: 0 0 15px 0;
		display: flex;
		flex-direction: column;
		grid-gap: 4px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #808080;

		&--type {
			display: block;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			color: #c99d56;
		}

		> div {
			&:first-child {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
			}
			&:last-child {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				p {
					margin: 0;
				}
			}
		}
	}

	&__properties {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		justify-content: space-between;

		li {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-basis: 100%;
			grid-gap: 10px;
			div {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
	}

	.estate-property {
		&__studio {
			margin-left: 9px;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			color: #c99d56;
		}

		&__value {
			display: inline-block;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			color: #c99d56;
			margin-left: 9px;
			margin-right: 5px;
		}

		&__title {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
		}
	}

	&__date {
		margin: 15px 0 0 0;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #808080;
		text-align: left;
	}
}
</style>
