<template>
	<div class="edit-button" role="radiogroup">
		<span
			v-for="item in modelData"
			:key="item.value"
			:class="modelValue !== item.value ? 'solid-border' : 'main'"
			@click="() => select(item.value)"
			role="radio"
			class="edit-button__item button"
			:aria-checked="modelValue === item.value"
		>
			{{ item.title }}
		</span>
	</div>
</template>

<script setup>
const props = defineProps(['modelData', 'modelValue'])
const emit = defineEmits(['update:modelValue'])

function select(value) {
	emit('update:modelValue', value)
}
</script>

<style scoped lang="scss">
@import '@/styles/components/button';

.edit-button {
	display: flex;
	gap: 10px;
	&__item.button {
		width: 100%;
		height: 48px;
	}
}
</style>
