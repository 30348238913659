<template>
	<div class="company-modal">
		<div class="company-modal__body">
			<company-agent
				v-for="subscription in content.subscriptions"
				:key="subscription.id"
				:agent="subscription.agent"
				:subscription="subscription"
        :checked="selectedSubscription.has(subscription.id)"
				@update="updateSelectedSubscriptions"
			/>
		</div>
		<or-button
			class="w-180 company-modal__btn"
			:disabled="selectedSubscription.size === 0"
			@click="proceedToChangePlan"
		>
			Next
		</or-button>
	</div>
</template>

<script>
import CompanyAgent from '@/components/modals/CompanyOffice/components/CompanyAgent.vue'
import CompanyFreeSlot from '@/components/modals/CompanyOffice/components/CompanyFreeSlot.vue'
import CompanyNoSlot from '@/components/modals/CompanyOffice/components/CompanyNoSlot.vue'
import OrButton from '@/components/global/orButton.vue'

export default {
	name: 'ExtendSlotsModal',
	components: { OrButton, CompanyNoSlot, CompanyFreeSlot, CompanyAgent },
	props: {
		content: Object
	},
	data() {
		return {
			selectedSubscription: new Set()
		}
	},
	methods: {
		updateSelectedSubscriptions(id) {
			if (this.selectedSubscription.has(id)) {
				this.selectedSubscription.delete(id)
			} else {
				this.selectedSubscription.add(id)
			}
		},
		proceedToChangePlan() {
			const ids = Array.from(this.selectedSubscription)
			this.$store.commit('hideModal')
			this.$router.push({
				name: 'Tariffs',
				params: { lang: this.$store.state.uiLanguage },
				query: { subscription_ids: ids }
			})
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/components/modal/company-modal';
</style>
