import { createI18n } from 'vue-i18n/index'
import store from '@/store'

const langList = {}

export async function addTranslationLines(lang) {
	if (
		process.env.VUE_APP_ACCEPTED_LANGUAGES?.split(',').includes(lang) &&
		!Object.keys(langList).includes(lang)
	) {
		store.commit('setIsLangFileLoaded', false)
		import(`@/locales/${lang}.json`)
			.then((result) => {
				langList[lang] = result
				if (i18n) i18n.global.setLocaleMessage(lang, result.default)
			})
			.finally(() => {
				store.commit('setIsLangFileLoaded', true)
			})
	}
}

addTranslationLines(store.state.uiLanguage)
addTranslationLines(process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en')

export function getBrowserLocale(options = {}) {
	const defaultOptions = { countryCodeOnly: false }
	const opt = { ...defaultOptions, ...options }
	const navigatorLocale =
		navigator.languages !== undefined
			? navigator.languages[0]
			: navigator.language
	if (!navigatorLocale) {
		return undefined
	}
	return opt.countryCodeOnly
		? navigatorLocale.trim().split(/[-_]/)[0]
		: navigatorLocale.trim()
}

function calculateInitialLocale() {
	const browserLocale = getBrowserLocale({ countryCodeOnly: true })
	const isBrowserLocaleAccepted = process.env.VUE_APP_ACCEPTED_LANGUAGES?.split(
		','
	).find((lang) => browserLocale === lang)

	return isBrowserLocaleAccepted
		? browserLocale
		: process.env.VUE_APP_I18N_FALLBACK_LOCALE
}

export const i18n = createI18n({
	locale: store.state.uiLanguage || calculateInitialLocale(),
	allowComposition: true,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: langList,
	warnHtmlInMessage: 'off',
	silentTranslationWarn: true,
})
