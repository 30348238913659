export function setMetaTag({ key, value }, content) {
	let meta
	meta = document.head.querySelector(`meta[${key}="${value}"]`)

	if (meta) {
		meta.content = content

		return
	}

	meta = document.createElement('meta')
	meta.setAttribute(key, value)
	meta.content = content
	document.head.appendChild(meta)
}

export function setCommonMetaData(title, metas) {
	document.title = title

	const metaConfig = [
		{
			key: 'name',
			value: 'description',
			content: 'description'
		},
		{
			key: 'property',
			value: 'og:type',
			content: 'type'
		},
		{
			key: 'property',
			value: 'og:title',
			content: 'title'
		},
		{
			key: 'property',
			value: 'og:description',
			content: 'description'
		},
		{
			key: 'property',
			value: 'og:url',
			content: 'url'
		}
	]

	metaConfig.forEach((itm) => {
		if (metas[itm.content]) {
			setMetaTag({ key: itm.key, value: itm.value }, metas[itm.content])
		}
	})
}
