<template>
	<form @submit.prevent="submit" class="add-agent">
		<div class="input-wrapper">
			<label for="add-agent-name">{{ $t('web.name') }}</label>
			<or-input
				required
				v-model="form.name"
				id="add-agent-name"
				class="main-type"
				:placeholder="$t('web.name')"
				@input="clearError('name')"
				:error="errors.name"
			/>
		</div>

		<div class="input-wrapper">
			<label for="add-agent-email">{{ $t('web.email') }}</label>
			<or-input
				required
				v-model="form.broker_email"
				id="add-agent-email"
				class="main-type"
				:placeholder="$t('web.email')"
				@input="clearError('broker_email')"
				:error="errors.broker_email"
			/>
		</div>

		<or-button type="submit" :height="48">
			{{ $t('web.send_invitation') }}
		</or-button>
	</form>
</template>

<script>
import requestConfigs from '@/api/requestBrokerConfigs'
import { createRequestBroker } from '@/api/requestBuilder'

export default {
	name: 'AddAgent',
	props: {
		content: {
			type: Object,
			default: () => ({
				callback: () => {}
			})
		}
	},
	data() {
		return {
			form: {
				name: '',
				broker_email: ''
			},
			errors: {}
		}
	},
	beforeMount() {
		Object.keys(this.form).forEach((key) => {
			// Set field errors
			this.errors[key] = { text: '', status: false }
		})
	},
	methods: {
		clearError(type) {
			this.errors[type].status = false
			this.errors[type].text = ''
		},
		submit() {
			const { commit } = this.$store
			const { name } = this.form

			if (name.length < 3) {
				this.errors.name.status = true

				commit('showNotif', {
					type: 'error',
					text: this.$t('web.notification_add_agent_name')
				})

				return
			}

			let isSubmit = true

			Object.entries(this.errors).forEach(([key, value]) => {
				if (key === 'status' && value) {
					isSubmit = false
				}
			})

			if (!isSubmit) {
				return
			}

			createRequestBroker(requestConfigs.POSTAccountAgencyAddBroker, {
				jsonPayload: {
					...this.form
				}
			})
				.then((response) => {
					if (response.status) {
						commit('showNotif', {
							type: 'success',
							text: this.$t('web.notification_add_agent_success')
						})

						this.content.callback()

						commit('hideModal')
					}
				})
				.catch((err) => {
					if (Object.keys(err.errors).length) {
						Object.entries(err.errors).forEach(([key, value]) => {
							if (this.errors[key]) {
								this.errors[key].status = true
								this.errors[key].text = value.join(', ')
							}
						})
					}
				})
		}
	}
}
</script>

<style lang="scss">
.add-agent {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 40px 60px;
	border-radius: 4px;
	background: #fff;
	max-width: 460px;
	width: 100%;
	@media screen and (max-width: 479px) {
		padding: 20px;
		gap: 20px;
	}
}
</style>
